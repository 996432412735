import { FunctionComponent, ReactElement, useState, useEffect } from "react";

import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  Avatar,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import HomeIcon from "@mui/icons-material/Home";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import GavelIcon from "@mui/icons-material/Gavel";
import GroupIcon from "@mui/icons-material/Group";
import CoPresent from "@mui/icons-material/CoPresent";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";

import styles from "./styles.module.scss";

import subject, { OPEN_LEFT_BAR } from "../../../utils/subjects";
import { useLocalStorage, useProfile } from "../../hooks";
import { posthogUtils } from "utils/analytics";
import { delTokenSSID } from "utils/axios";
import { isAccessTokenExpired } from "utils";
import { Profile } from "shared/types/Profile";
import { getImgBaseUrl } from "utils/links";
import { HStack } from "shared/components";

export interface ILeftBarMenuProps {
  role: string;
  profile?: Profile;
}
const LeftBarMenu: FunctionComponent<ILeftBarMenuProps> = ({
  profile,
}): ReactElement<any> => {
  const [open, setOpen] = useState(false);
  const [name] = useLocalStorage("name");
  const [role] = useLocalStorage("role");
  const [email] = useLocalStorage("email");
  const hasName = !name && name !== "undefined undefined";

  useEffect(() => {
    const listenerLeftBar = subject
      .listen(OPEN_LEFT_BAR)
      .subscribe(async ({ value }) => {
        setOpen(value);
      });

    return () => {
      if (listenerLeftBar) {
        listenerLeftBar.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (
      !name ||
      !role ||
      !email ||
      email === "null" ||
      isAccessTokenExpired()
    ) {
      logout();
    }
  }, [name, role, email]);

  const logout = () => {
    posthogUtils.resetUser();
    localStorage.removeItem("access_token");
    localStorage.clear();
    delTokenSSID();
    window.location.href = "/sign-in";
  };

  return (
    <Drawer open={open} anchor="left" onClose={() => setOpen(false)}>
      <Box className="MuiPersonalInfo-root">
        <Avatar
          alt="User profile"
          src="/assets/img/avatar/a9.svg"
          sx={{ width: 50, height: 50 }}
        />

        <Typography variant="h6">{hasName ? name : email}</Typography>
        <Typography variant="caption">
          {role === "client" ? "Cliente" : ""}
          {role === "broker" ? "Broker" : ""}
          {role === "realstate" ? "Inmobiliaria" : ""}
          {role === "influencer" ? "Influencer" : ""}
          {role === "bank" ? "Banco" : ""}
           {role === "partner" ? "Partner" : ""}
        </Typography>
      </Box>

      {role === process.env.REACT_APP_CLIENT_ROLE && (
        <ClientMenu onLogout={() => logout()} />
      )}
      {(role === process.env.REACT_APP_BROKER_ROLE ||
        role === process.env.REACT_APP_BANK_ROLE) && (
          <BrokerMenu onLogout={() => logout()} profile={profile} />
        )}
      {role === process.env.REACT_APP_REAL_ESTATE_ROLE && (
        <RealEstateMenu onLogout={() => logout()} />
      )}
      {role === process.env.REACT_APP_INFLUENCER_ROLE && (
        <InfluencerMenu onLogout={() => logout()} />
      )}
      {role === process.env.REACT_APP_PARTNER_ROLE && (
        <PartnerMenu onLogout={() => logout()} />
      )}
      {/* {role === process.env.REACT_APP_BANK_ROLE && (
        <BrokerMenu onLogout={() => logout()} profile={profile}/>
      )} */}

      <Chip label="v1.0.0" className={styles.BetaBadge} />
    </Drawer>
  );
};

export interface IClientMenuProps {
  onLogout: () => void;
}
const ClientMenu: FunctionComponent<IClientMenuProps> = ({ onLogout }) => {
  const navigate = useNavigate();
  const [tmpAuth] = useLocalStorage("tmpAuth");

  return (
    <List>
      <ListItem
        button
        onClick={() => {
          navigate("/client/home");
        }}
      >
        <ListItemIcon>
          <HomeIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Panel principal" />
      </ListItem>

      <ListItem
        button
        onClick={() => {
          navigate("/client/mortgages");
        }}
      >
        <ListItemIcon>
          <AssignmentOutlinedIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Mis solicitudes" />
      </ListItem>

      <ListItem
        button
        onClick={() => {
          navigate("/client/profile/documents");
        }}
      >
        <ListItemIcon>
          <FolderSpecialIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Mis documentos" />
      </ListItem>
    
      {!tmpAuth &&    <ListItem
        button
        onClick={() => {
          navigate("/referrals");
        }}
      >
        <ListItemIcon>
          <CoPresent color="primary" />
        </ListItemIcon>
        <ListItemText primary="Mis Referidos" />
      </ListItem>}

      {!tmpAuth &&  <ListItem
        button
        onClick={() => {
          navigate("/client/profile");
        }}
      >
        <ListItemIcon>
          <AccountCircleOutlinedIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Mi Perfil" />
      </ListItem>}
  
      {!tmpAuth && <ListItem button onClick={() => onLogout()}>
        <ListItemIcon>
          <LogoutOutlinedIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Cerrar Sesión" />
      </ListItem>}

      {tmpAuth && <ListItem button onClick={async () => {
        const data = JSON.parse(tmpAuth);

        await localStorage.setItem("access_token", data.lsToken);
        await localStorage.setItem("email", data.lsEmail);
        await localStorage.setItem("name", data.lsName);
        await localStorage.setItem("role", data.lsRole);
        await localStorage.setItem("username", data.lsUsername);
        await localStorage.removeItem("tmpAuth");

        window.location.href = "/partner/clients";
      }}>
        <ListItemIcon>
          <LogoutOutlinedIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Salir de esta cuenta" />
      </ListItem>}

    </List>
  );
};

export interface IBrokerMenuProps {
  onLogout: () => void;
  profile?: Profile;
}
const BrokerMenu: FunctionComponent<IBrokerMenuProps> = ({
  onLogout,
  profile,
}) => {
  const navigate = useNavigate();

  return (
    <List>
      <ListItem
        button
        onClick={() => {
          navigate("/broker/applications");
        }}
      >
        <ListItemIcon>
          <TextSnippetIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Aplicaciones" />
      </ListItem>

      <ListItem
        button
        onClick={() => {
          navigate("/broker/bids");
        }}
      >
        <ListItemIcon>
          <GavelIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Mis pujas" />
      </ListItem>

      <ListItem
        button
        onClick={() => {
          navigate("/broker/clients");
        }}
      >
        <ListItemIcon>
          <GroupIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Clientes" />
      </ListItem>

      <ListItem
        button
        onClick={() => {
          navigate("/broker/auto-bidding");
        }}
      >
        <ListItemIcon>
          <ManageHistoryIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Auto pujas" />
      </ListItem>

      <ListItem
        button
        onClick={() => {
          navigate("/broker/profile");
        }}
      >
        <ListItemIcon>
          <AccountCircleOutlinedIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Mi Perfil" />
      </ListItem>

      {/* {profile &&
        "require_purchase_package" in profile &&
        profile.require_purchase_package && (
          <ListItem
            button
            onClick={() => {
              navigate("/broker/planes");
            }}
          >
            <ListItemIcon>
              <img src={getImgBaseUrl("price_list.png")} height="26px" />
            </ListItemIcon>
            <ListItemText primary="Planes" />
          </ListItem>
        )} */}
      <ListItem
        button
        onClick={() => {
          navigate("/broker/colibid-data");
        }}
      >
        <ListItemIcon>
          <img src={getImgBaseUrl("data-visualization.svg")} height="26px" />
        </ListItemIcon>
        <ListItemText primary="Colibid Data" />
        {/* {
        <HStack gap={3} alignItems="center">
          <Typography>Colibid Data</Typography>
          <img src={getImgBaseUrl("new_logo.svg")} height="30px" />
        </HStack>
      } */}
      </ListItem>

      <ListItem button onClick={() => onLogout()}>
        <ListItemIcon>
          <LogoutOutlinedIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary="Cerrar Sesión" />
      </ListItem>
    </List>
  );
};

export interface IRealEstateMenuProps {
  onLogout: () => void;
}
const RealEstateMenu: FunctionComponent<IRealEstateMenuProps> = ({
  onLogout,
}) => {
  const navigate = useNavigate();

  return (
    <List>
      <ListItem button onClick={() => navigate("/agent/profile")}>
        <ListItemIcon>
          <img
            src="https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/profile.gif"
            alt="Colibid"
            className={styles.iconStyle}
          ></img>
        </ListItemIcon>
        <ListItemText primary="Mi Perfil" />
      </ListItem>

      <ListItem button onClick={() => navigate("/agent/howworks")}>
        <ListItemIcon>
          <img
            src="https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/howworkstome.gif"
            alt="Colibid"
            className={styles.iconStyle}
          ></img>
        </ListItemIcon>
        <ListItemText primary="Cómo funciona para mí" />
      </ListItem>

      <ListItem button onClick={() => navigate("/agent/howworks/colibid")}>
        <ListItemIcon>
          <img
            src="https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/logo.png"
            alt="Colibid"
            className={styles.iconStyle}
          ></img>
        </ListItemIcon>
        <ListItemText primary="Cómo opera Colibid" />
      </ListItem>

      <ListItem button onClick={() => navigate("/agent")}>
        <ListItemIcon>
          <img
            src="https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/Inicio.gif"
            alt="Colibid"
            className={styles.iconStyle}
          ></img>
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>

      <ListItem button onClick={() => onLogout()}>
        <ListItemIcon>
          <img
            src="https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/logout.png"
            alt="Colibid"
            className={styles.iconStyle}
          ></img>
        </ListItemIcon>
        <ListItemText primary="Cerrar Sesión" />
      </ListItem>
    </List>
  );
};

const InfluencerMenu: FunctionComponent<IRealEstateMenuProps> = ({
  onLogout,
}) => {
  const navigate = useNavigate();

  return (
    <List>
      <ListItem button onClick={() => navigate("/referrals")}>
        <ListItemIcon>
          <img
            src="https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/Inicio.gif"
            alt="Colibid"
            className={styles.iconStyle}
          ></img>
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>

      <ListItem button onClick={() => onLogout()}>
        <ListItemIcon>
          <img
            src="https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/logout.png"
            alt="Colibid"
            className={styles.iconStyle}
          ></img>
        </ListItemIcon>
        <ListItemText primary="Cerrar Sesión" />
      </ListItem>
    </List>
  );
};

export interface IPartnerMenuProps {
  onLogout: () => void;
}
const PartnerMenu: FunctionComponent<IPartnerMenuProps> = ({
  onLogout,
}) => {
  const navigate = useNavigate();

  return (
    <List>
      <ListItem button onClick={() => navigate("/partner/profile")}>
        <ListItemIcon>
          <img
            src="https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/profile.gif"
            alt="Colibid"
            className={styles.iconStyle}
          ></img>
        </ListItemIcon>
        <ListItemText primary="Mi Perfil" />
      </ListItem>

      <ListItem button onClick={() => navigate("/partner")}>
        <ListItemIcon>
          <img
            src="https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/Inicio.gif"
            alt="Colibid"
            className={styles.iconStyle}
          ></img>
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>

      <ListItem button onClick={() => onLogout()}>
        <ListItemIcon>
          <img
            src="https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/logout.png"
            alt="Colibid"
            className={styles.iconStyle}
          ></img>
        </ListItemIcon>
        <ListItemText primary="Cerrar Sesión" />
      </ListItem>
    </List>
  );
};

export default LeftBarMenu;
