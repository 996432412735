import React, {
  FunctionComponent,
  ReactComponentElement,
  useEffect,
  useState,
} from "react";

import {
  Container,
  Grid, List, ListItem,
  ListItemText,
  Divider,
  ListSubheader
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

import {
  MainPartnerLayout
} from "../../../shared/layouts";
import { PageTitle } from "../../../shared/components";

// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PolicyIcon from "@mui/icons-material/Policy";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import CookieIcon from "@mui/icons-material/Cookie";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {
  useBankAccountUser,
  useDialog,
  useLocalStorage,
  useProfile,
} from "../../../shared/hooks";
import { useAuthenticated } from "../../../shared/hooks";
import styles from "./styles.module.scss";
import {
  getAgentDocuments
} from "services/api";
import { toast } from "react-toastify";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { BankDetails } from "pages/Referrals/ReferralPay";
import { RealStateProfile } from "shared/types/Profile";
import { posthogUtils } from "utils/analytics";
import { delTokenSSID } from "utils/axios";

const PartnerProfilePage: FunctionComponent<{}> =
  (): ReactComponentElement<any> => {
    const { isShowing, closeDialog, openDialog } = useDialog();
    const [name] = useLocalStorage("name");
    const [email] = useLocalStorage("email");
    const [role] = useLocalStorage("role");
    const { profile, setValueProfile } = useProfile<RealStateProfile>();
    const navigate = useNavigate();
    const { paymentData, setPaymentValue } = useBankAccountUser();

    useAuthenticated((allow) => {
      if (!allow) navigate("/sign-in");
    }, "partner");

    const logout = () => {
      posthogUtils.resetUser();
      localStorage.removeItem("access_token");
      localStorage.clear();
      delTokenSSID();
      window.location.href = "/sign-in";
    };

    return (
      <MainPartnerLayout>
        <>
        <br /><br />
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} display="flex" alignItems="center">
                <img
                  width="30px"
                  height="30px"
                  src="https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/profile.gif"
                  alt="Colibid"
                  style={{
                    marginRight: "1rem",
                  }}
                />
                <PageTitle title="Mi Perfil" />
              </Grid>
            </Grid>
          </Container>

          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <List
                  className={styles.ListGroup}
                  sx={{ marginTop: "1.5rem", backgroundColor: "transparent" }}
                >
                  {profile && (
                    <React.Fragment>
                      <ListItem className={styles.ListItem}>
                        <ListItemText
                          primary="Nombre"
                          className={styles.listItemText}
                        />
                        <ListItemText
                          primary={name}
                          className={styles.listItemText}
                        />
                      </ListItem>

                      <Divider component="li" />
                      <ListItem className={styles.ListItem}>
                        <ListItemText primary="Email" />
                        <ListItemText
                          primary={email}
                          className={styles.listItemText}
                        />
                      </ListItem>
                      <Divider component="li" />
                      <ListItem className={styles.ListItem}>
                        <ListItemText primary="Teléfono" />
                        <ListItemText
                          primary={profile.phone}
                          className={styles.listItemText}
                        />
                      </ListItem>

                      <Divider />
                    </React.Fragment>
                  )}

                  <Divider component="li" />
                  <ListItem
                    button
                    onClick={() => navigate("/partner/profile/changepassword")}
                    className={styles.ListItem}
                  >
                    <ListItemText primary="Cambiar contraseña" />
                    <LockOutlinedIcon className={styles.ListIcon} />
                  </ListItem>
                </List>

                <List
                  subheader={
                    <ListSubheader className={styles.SubTitle}>
                      ACERCA DE
                    </ListSubheader>
                  }
                  className={styles.ListGroup}
                >
                  <ListItem
                    className={styles.ListItem}
                    button
                    component="a"
                    href="https://colibid.com/terminos-y-condiciones/"
                    target="n_blank"
                  >
                    <ListItemText primary="Términos y Condiciones" />
                    <InfoOutlinedIcon className={styles.ListIcon} />
                  </ListItem>
                  <Divider component="li" />
                  <ListItem
                    className={styles.ListItem}
                    button
                    component="a"
                    href="https://colibid.com/privacy-policy/"
                    target="n_blank"
                  >
                    <ListItemText primary="Política de Privacidad" />
                    <PolicyIcon className={styles.ListIcon} />
                  </ListItem>
                  <Divider component="li" />
                  <ListItem
                    className={styles.ListItem}
                    button
                    component="a"
                    href="https://colibid.com/politica-de-cookies/"
                    target="n_blank"
                  >
                    <ListItemText primary="Política de Cookies" />
                    <CookieIcon className={styles.ListIcon} />
                  </ListItem>
                  <Divider component="li" />
                  <ListItem
                    className={styles.ListItem}
                    button
                    component="a"
                    href="https://colibid.com/preguntas-frecuentes/"
                    target="n_blank"
                  >
                    <ListItemText primary="Preguntas Frecuentes" />
                    <ContactSupportOutlinedIcon className={styles.ListIcon} />
                  </ListItem>
                  <Divider component="li" />
                  <ListItem
                    button
                    onClick={() => logout()}
                    className={styles.ListItem}
                  >
                    <ListItemText primary="Cerrar Sesión" />
                    <LogoutOutlinedIcon className={styles.ListIcon} />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Container>
        </>
      </MainPartnerLayout>
    );
  };

export default PartnerProfilePage;
