import { Bid } from "shared/types/Bid";
import { TableRows } from "../InfoCard";

function getBonificationData(cd: Bid["conditions_details"]) {
  const cdData: Record<string, string> = {};

  cd.filter((b) => b.allow).forEach((c) => {
    cdData[c.bonificationName] = `${c.interest_rate}%`;
    
  });

  cdData['Total'] = String(cd.filter((b) => b.allow).reduce((sum, bonus) => sum + Number(bonus.interest_rate), 0)) + "%";  
  return cdData;
}

export default function BonificationTable({
  conditionsDetails,
}: {
  conditionsDetails: Bid["conditions_details"];
}) {
  const bonificationData: Record<string, string> = ({} =
    getBonificationData(conditionsDetails));
  return <TableRows data={bonificationData} />;
}
