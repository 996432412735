import React, {
  FunctionComponent,
  ReactElement,
  useState,
  useEffect,
} from "react";
import { TopBar, LeftBarMenu, Loader, BottomNav } from "../../components";

import subject, { TOGGLE_LOADER } from "../../../utils/subjects";
import { useProfile, useSetVhRef } from "shared/hooks";
import { InfoDialog } from "pages/RealState/Components";

// Get email from localstorage
const email = localStorage.getItem("email")
  ? localStorage.getItem("email")
  : "";

export interface IMainPartnerLayoutProps {
  children: JSX.Element;
}

const navOptions = [  
  {
    label: "Solicitud de hipoteca",
    path: "/partner/apply",
    iconUrl:
      "https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/Solicitarhipotecabottommenu.png",
  },
  {
    label: "Home",
    path: "/partner",
    iconUrl:
      "https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/homeBottoMenu.png",
  },
  {
    label: "Mis clientes",
    path: "/partner/clients",
    iconUrl:
      "https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/MisClientesbottommenu.png",
  },
];

const MainPartnerLayout: FunctionComponent<IMainPartnerLayoutProps> = ({
  children,
}): ReactElement<any> => {
  const { elementRef: mainSectionRef } = useSetVhRef();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const toggleLoader = subject
      .listen(TOGGLE_LOADER)
      .subscribe(async ({ value }) => {
        setLoading(value);
      });

    return () => {
      if (toggleLoader) {
        toggleLoader.unsubscribe();
      }
    };
  }, []);

  // Get image from backend

  return (
    <>
      {loading && <Loader />}

      <TopBar
        linkColibid="/partner"
        allowLeftBarMenu={true}
        showAuthControls={true}
        imageCompany={""}
      />
      <LeftBarMenu role="partner" />
      <InfoDialog />
      <section id="content" className="main-layout" ref={mainSectionRef}>
        {children}
      </section>

      <BottomNav navBtnOptions={navOptions} />
    </>
  );
};

export default MainPartnerLayout;
