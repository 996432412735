import {
  FunctionComponent,
  ReactComponentElement,
  useEffect,
  useState,
} from "react";

import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { authLead, getListClient } from "services/api/partner.service";
import { MainPartnerLayout } from "shared/layouts";

import styles from "./styles.module.scss";
import { modifyData } from "./utils";

import {
  IconButton,
  Box,
  Chip,
  Container,
  Grid,
  Typography
} from "@mui/material";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

const ClientsRealState: FunctionComponent<{}> =
  (): ReactComponentElement<any> => {
    const [clients, setClients] = useState<Array<any>>([]);


    const columns: GridColDef[] = [
      {
        field: 'fullname',
        headerName: 'Nombre completo',
        width: 300,
        renderCell: (params) => {
          return <>
            <div>
              <p className={styles.NameClient}>{params.value}</p>
              {params.row.awaitingSignature > 0 && <Chip
                label="Pendiente firma de documentos"
                color="warning"     
                size="small"
                className={styles.NameChip}

              />}
            </div>
            
          </>
        }
      },
      {
        field: 'email',
        headerName: 'Email',
        width: 300,
      },
      {
        field: 'mortgageType',
        headerName: 'Tipo de solicitud',
        width: 180,
      },
      {
        field: 'status',
        headerName: 'Estado',
        width: 220,
        renderCell: (params) => {
          if (params.value === "draft") {
            return <Chip
              label="Borrador"
              color="error"
              variant="outlined"
              className={styles.ChipGray}
            />
          }

          if (params.value === "collapsed") {
            return <Chip
              label="Operación cancelada"
              color="error"
              variant="outlined"
              className={styles.ChipAuto}
            />
          }

          if (params.value === "paid") {
            return <Chip
              label="Completado"
              color="success"
              variant="outlined"
              className={styles.ChipAuto}
            />
          }

          if (params.value === "closed_successfully") {
            return <Chip
              label="Cliente dado de alta"
              color="warning"
              variant="outlined"
              className={styles.ChipBrown}
            />
          }

          if (params.value === "expired") {
            return <Chip
              label="Solicitud expirada"
              color="warning"
              variant="outlined"
              className={styles.ChipGray}
            />
          }

          if (params.value === "in_verification_accepted") {
            return <Chip
              label="Documentos pendientes"
              color="warning"
              variant="outlined"
              className={styles.ChipAuto}
            />
          }

          if (params.value === "closed") {
            return <Chip
              label="Subasta cerrada"
              color="info"
              variant="outlined"
              className={styles.ChipYellow}
            />
          }

          if (params.value === "active") {
            return <Chip
              label="Subasta activa"
              color="info"
              variant="outlined"
              className={styles.ChipAuto}
            />
          }
        },
      },
      {
        field: 'id',
        headerName: 'Acciones',
        width: 90,
        renderCell: (params) => {
          return (
            <>
              <IconButton aria-label="edit" onClick={() => handleManageAccount(params.value, params.row.fullname)}>
                <EditIcon color="primary" />
              </IconButton>
            </>
          );
        },
      },
    ];

    const handleManageAccount = async (id: string, name: string) => {
      Swal.fire({
        title: "¿Estás seguro?",
        text: `Al aceptar estarás ingresando a la cuenta del cliente ${name}, allí podras gestionar en su nombre toda la solicitud`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then(async (result: any) => {
        if (result.isConfirmed) {
          const auth = await authLead(id);
          const lsToken = await localStorage.getItem("access_token");
          const lsEmail = await localStorage.getItem("email");
          const lsName = await localStorage.getItem("name");
          const lsRole = await localStorage.getItem("role");
          const lsUsername = await localStorage.getItem("username");
          const tmpAuth = JSON.stringify({ lsToken, lsEmail, lsName, lsRole, lsUsername })

          if (auth) {
            const lead = auth.data.results;
            await localStorage.setItem("tmpAuth", tmpAuth);
            await localStorage.setItem("access_token", lead.ssid);
            await localStorage.setItem("email", lead.email);
            await localStorage.setItem("name", lead.name);
            await localStorage.setItem("role", lead.role);
            await localStorage.setItem("username", lead.email);

            window.location.href = "/client/home";
          }
        }
      });
    };

    useEffect(() => {
      getClientsData();
    }, []);

    const getClientsData = () => {
      getListClient({ itemsPerPage: 100, page: 1 })
        .then((response) => {
          const modify = modifyData(response.data);
          setClients(modify.data);
        })
        .catch((e: any) => {
          toast.error("Lo sentimos ha ocurrido un error inesperado listando los clientes😔", e);
          console.log("error cliente", e);
        });
    };

    return (
      <MainPartnerLayout>
        <Container className={styles.HomeWrapper}>
          <Grid item md={12} sm={12} xs={12}>
            <Box className={styles.CaptionHeader}>
              <Typography variant="h6" className={styles.MainTitle}>Mis clientes</Typography>
              <Typography variant="caption" className={styles.MainCaption}>Se muestra el listado de clientes adquiridos hasta la fecha, si desea puede gestionar cada cuenta haciendo clic en la acción correspondiente.</Typography>
            </Box>
            <Box sx={{ height: 600, width: '100%' }} className={styles.BoxPaper}>
              <DataGrid
                rows={clients}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
              />
            </Box>
          </Grid>
        </Container>
      </MainPartnerLayout>
    );
  };

export default ClientsRealState;
