import { Interface } from "readline";
import { MortgageModeRanges } from "shared/types/postMortgageData";

export const PROGRESS_BAR = "progress_bar";

export const COLIBID_FEE = 0.12;
export const COLIBID_FEE_AUTOPROMOTOR = 0.1;

/**  This ages should add at leash 10 years of the data period mortgage
so 70 is the max plus 10 is 80 years and is a no no, cause too old
vale verg ser viejo (ノಠ益ಠ)ノ彡┻━┻, quiero mi juventud ahhhhh (⊃‿⊂)*/
export const USER_AGE_RANGE = {
  min: 18,
  max: 65,
};
export const WARNING_CLIENT_AGE = 60;
/** This const is only used for labeling not used for any calculations at all*/
export const MAX_CLIENT_AGE_MARKETING_MOVE = 70;

export const MAX_MORTGAGE_LAP = 80;
export const WARNING_MORTGAGE_LAP = 75;

/** This const is applicable for mode 2 and 3 when a user have a remaining mortgage pending to pay.*/
export const PENDING_AMOUNT_RANGES: MortgageModeRanges = {
  NEW_MORTGAGE: { MIN: 75000, MAX: 2_000_000 },
  REMORTGAGE: { MIN: 75000, MAX: 2_000_000 },
  CAPITAL_RAISE: { MIN: 10000, MAX: 2_000_000 },
  AUTOPROMOTOR: { MIN: 75000, MAX: 2_000_000 },
  GLOBAL: { MIN: 10000, MAX: 2_000_000 },
};

/** Global is a generic key for situation like define filters ranges. MIN= MIN VALUE BETWEEN Mortgages mode in this capital rise, MAX is the same in all the scenarios. */
export const MORTGAGE_AMOUNT_RANGES = {
  GLOBAL: { MIN: 10000, MAX: 2_000_000 },
  NEW_MORTGAGE: { MIN: 50000, MAX: 2_000_000 },
  REMORTGAGE: { MIN: 50000, MAX: 2_000_000 },
  CAPITAL_RAISE: { MIN: 10000, MAX: 2_000_000 },
  AUTOPROMOTOR: { MIN: 50000, MAX: 2_000_000 },
};

export const PROPERTY_VALUE_RANGES = {
  GLOBAL: { MIN: 50000, MAX: 2_000_000 },
  NEW_MORTGAGE: { MIN: 50000, MAX: 2_000_000 },
  REMORTGAGE: { MIN: 50000, MAX: 2_000_000 },
  CAPITAL_RAISE: { MIN: 50000, MAX: 2_000_000 },
  AUTOPROMOTOR: { MIN: 50000, MAX: 2_000_000 },
};

export const CURRENT_ORIGINAL_AMOUNT_RANGE = {
  GLOBAL: { MIN: 20000, MAX: 2_500_000 },
  NEW_MORTGAGE: { MIN: 20000, MAX: 2_500_000 },
  REMORTGAGE: { MIN: 20000, MAX: 2_500_000 },
  CAPITAL_RAISE: { MIN: 20000, MAX: 2_500_000 },
  AUTOPROMOTOR: { MIN: 20000, MAX: 2_500_000 },
};

export const MIN_DATA_PERIOD_YEARS = 15;
export const MAX_DATA_PERIOD_YEARS_INTEREST_FIJO = 30;
export const MAX_DATA_PERIOD_YEARS_INTEREST_NOT_FIJO = 35;

export const MAX_MONTHLY_INCOME = 1_000_000;

export const PROPERTY_IMPORT_MIN_VALUE = 10000;

export const steps = [
  "Registro",
  "Hipoteca",
  "Dirección",
  "Ingresos",
  // "DNI",
  "Confirmación",
];

export const PROPERTY_AREA_RANGE = {
  min: 30,
  max: 3000,
} as const;

export const INTEREST_RATE_RANGE = {
  min: 0,
  max: 10,
};

export const MORTGAGE_INTEREST_RANGES: MortgageModeRanges = {
  GLOBAL: { MIN: 0, MAX: 10 },
  NEW_MORTGAGE: { MIN: 0, MAX: 10 },
  REMORTGAGE: { MIN: 0.001, MAX: 10 },
  CAPITAL_RAISE: { MIN: 0, MAX: 10 },
  AUTOPROMOTOR: { MIN: 0, MAX: 10 },
};
export const REMORTGAGE_INTEREST_RATE_RANGE = {
  min: 0.001,
  max: 10,
};

export const INTEREST_TYPE_REMORTGAGE_OPTIONS = [
  { value: "fijo", label: "Fijo" },
  { value: "variable", label: "Variable" },
];

export const MAX_MORTGAGE_RATE = {
  NEW_MORTGAGE: 100,
  REMORTGAGE: 95,
  CAPITAL_RAISE: 80
};

export const MAX_CURRENCY_NUMBER_VALUES = 2_500_000;
