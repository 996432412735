import { FunctionComponent, ReactComponentElement } from "react";
import { useNavigate } from "react-router-dom";
import { MortgageForm } from "shared/components";
import { MainPartnerLayout } from "shared/layouts";
import Swal from "sweetalert2";
import { MortgagePartnerAgencies } from "shared/types/BaseApiResponse";
import { partnerApplyMortgage } from "services/api/partner.service";
import { toast } from "react-toastify";
import { getInitialFormData } from "shared/components/MortgageForm/utils";
import dataStorageService from "services/api/dataStorage.service";
import { MORTGAGE_FORM_STATE } from "shared/constants/localStorage";
import { PostMortgageData } from "shared/types/postMortgageData";

const FormMortgage: FunctionComponent<{}> = (): ReactComponentElement<any> => {
  const navigate = useNavigate();

  const applyMortgage = (data: PostMortgageData) => {
    console.log(13, data, data.mortgage_data.user.name);
    Swal.fire({
      title: "¿Estás seguro?",
      text: `¿El cliente ${data.mortgage_data.user.name}  ${data.mortgage_data.user.surname}  quiere solicitar la subasta?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result: any) => {
      if (result.isConfirmed) {
        partnerApplyMortgage(data)
          .then(() => {
            toast.success("Solicitud registrada correctamente");
            dataStorageService.delData({ key: MORTGAGE_FORM_STATE });
            navigate("/partner");
          })
          .catch((e: any) => {
            toast.error(
              "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
            );
            console.log(e);
          });
      }
    });
  };

  return (
    <MainPartnerLayout>
      <MortgageForm
        onSubmitForm={async (data) => {
          applyMortgage(data);
        }}
      />
    </MainPartnerLayout>
  );
};

export default FormMortgage;
