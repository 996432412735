import React, { FunctionComponent, ReactComponentElement } from "react";
import { useLocalStorage } from "../../../shared/hooks";
import { Container, Grid } from "@mui/material";

import {
  MainPartnerLayout,
} from "../../../shared/layouts";

import styles from "./styles.module.scss";
import { ImageWithTitle } from "../Components";

const Home: FunctionComponent<{}> = (): ReactComponentElement<any> => {
  const images = [ 
    {
      title: "Realizar solicitud de hipoteca",
      imageUrl:
        "https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/Solicitarhipotecahome.png",
      targetPath: "/partner/apply",
      color: "#33CCCC",
    },
    {
      title: "Mis Clientes",
      imageUrl:
        "https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/misClientesHome.png",
      targetPath: "/partner/clients",
      color: "#01807A",
    },
  ];

 
  return (
    <MainPartnerLayout>
      <Container className={styles.HomeWrapper}>
        <Grid container spacing={2} className={styles.fullHeight} marginTop={0}>
            {images.map((image, index) => (
              <Grid key={index} item xs={12} sm={6}>
                <ImageWithTitle
                  title={image.title}
                  imageUrl={image.imageUrl}
                  targetPath={image.targetPath}
                  boxColor={image.color}
                  customStyle={{
                    width: "4rem",
                  }}
                  customStyleTitle={{
                    textAlign: "center",
                  }}
                />
              </Grid>
            ))}
          </Grid>
      </Container>
    </MainPartnerLayout>
  );
};

export default Home;
