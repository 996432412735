import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Button,
  Checkbox,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText
} from "@mui/material";
import { Star, Delete, Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { Bid } from "shared/types/Bid";
import { MortgageRes } from "shared/types/BaseApiResponse";
import { formatLocalCurrencyCustom } from "utils/helpers";
import { getComissionValue } from "utils/bids";
import BidDetailsWithTabs from "shared/components/BidSection/components/BidDetailsWithTabs";
import { CustomDialog } from "shared/components";
import { getBidderTypeInES } from "utils/translate";
import { MixInterestDialog } from "shared/components/BidSection/components/BidTable";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { BidReducerState } from "shared/components/BidSection/useBidReducer";
import { PPStepperData } from "pages/client/Home/components/PrincipalPanel/components/PPSteppers";
import { IconType } from "react-icons/lib";

import styles from "./styles.module.scss";

interface BidBubbleProps {
  bid: Bid;
  mode: 'await' | 'accepted';
  mortgage: MortgageRes;
  onSelect: (bid: string) => void;
  onTrash: (bid: string) => void;
  bidsState?: BidReducerState;
}

interface BidBubbleInterestLine {
  mode: string;
  rate: string;
  duration: number;
}

interface TimelineIconProps {
  label: string;
  size?: number;
  className: string;
}

export default function BidBubble({ bid, mode, mortgage, onSelect, onTrash, bidsState }: BidBubbleProps) {
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [isInterestMixto, setIsInterestMixto] = useState<boolean>(false);
  const [isInterestVariable, setIsInterestVariable] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [totalBonus, setTotalBonus] = useState<number>(0);
  const [mixInterestData, setMixInterestData] = useState<BidBubbleInterestLine[] | undefined>([]);
  const [isBidSelected, setIsBidSelected] = useState<boolean>(false);
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    setIsInterestMixto(bid.interest_data.mode === "mixto" || bid.interest_data.mode === "mixed");
    setIsInterestVariable(bid.interest_data.mode === "variable");
    setTotalBonus(bid.conditions_details.filter((b) => b.allow).reduce((sum, bonus) => sum + Number(bonus.interest_rate), 0));

    const mixInterest = bid.interest_data.interest?.map((di, i) => {
      let rate = `${di.rate}% - ${di.duration} años`;

      if (di.mode === "variable") {
        rate = `${di.rate}% <small>+Euribor</small> - ${di.duration} años`
      }

      return {
        mode: `Periodo (${i + 1}) ${di.mode}`,
        rate: rate,
        duration: di.duration
      }
    });

    setMixInterestData(mixInterest);
  }, [bid])

  useEffect(() => {
    if (bidsState) {
      const isSelected =
        bidsState.selectedBid?.some(
          (selectedBid) => selectedBid.id === bid.id
        ) || false;

      const filterSelectedBids = bidsState.bids.filter(
        (bid) => !bidsState.selectedBid?.includes(bid)
      );

      const filterSelectedBrokerBids = bidsState.selectedBid?.some(
        (bid) => bid.bidder.bidder_type === "broker"
      );

      let valuesToDisabled = filterSelectedBids?.filter(
        (bidNotSelected) => {
          return bidsState.selectedBid?.some(
            (selectedBid) => selectedBid.entity === bidNotSelected.entity
          );
        }
      );

      if (filterSelectedBrokerBids) {
        valuesToDisabled = filterSelectedBids?.filter(
          (bidNotSelected) => {
            return bidsState.selectedBid?.some(
              (selectedBid) => selectedBid.entity === bidNotSelected.entity || bidNotSelected.bidder.bidder_type == "broker"
            );
          }
        );
      }

      setIsDisabled(valuesToDisabled.some((item) => item.id === bid.id));
      setIsBidSelected(isSelected);
    }
  }, [bidsState, bid])

  const openCancelDialog = () => {
    setShowCancelDialog(true);
  };

  const closeCancelDialog = () => {
    setShowCancelDialog(false);
  };


  const handleSelectBid = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onSelect(value);
  };

  const TimelineIcon = ({ label, size = 24, className }: TimelineIconProps) => {
    const iconData = PPStepperData[label];

    if (!iconData) {
      console.warn(`No icon found for label: ${label}`);
      return null;
    }

    const IconComponent = iconData.icon;

    return <IconComponent size={size} className={className} />;
  };

  return (
    <div className={`${styles.BoxBubble} ${isDisabled ? styles.Disabled : ''}`}>
      {mode === "accepted" && <div className={styles.BubbleHeader}>
        <div className={styles.Title}>
          <BidderName bidder_type={bid.bidder.bidder_type} rating={bid.bidder.rating} />
        </div>
        <div className={styles.Action}>
          <IconButton
            onClick={() => { openCancelDialog() }}
          >
            <Delete />
          </IconButton>
        </div>
      </div>}
      <div className={styles.BidDetails}>

        <div className={styles.DetailRow}>
          <div className={styles.Label}>Interés</div>
          <div className={styles.Value}>{bid.interest_data.mode}</div>
        </div>

        {mixInterestData?.map((mId, i) => {
          return (
            <div className={styles.DetailRow} key={`ids${i}`}>
              <div className={styles.Label}>{mId.mode}</div>
              <div className={styles.Value} dangerouslySetInnerHTML={{ __html: mId.rate }}></div>
            </div>
          )
        })}

        {isInterestMixto && bid.interest_data.interest && <div className={styles.DetailRow}>
          <div className={styles.Label}>Tasa de Interés</div>
          <div className={styles.Value}><MixInterestDialog mD={bid.interest_data.interest} /></div>
        </div>}

        {!isInterestMixto && <div className={styles.DetailRow}>
          <div className={styles.Label}>Tasa de Interés</div>
          <div className={styles.Value}>{bid.interest_rate}% {isInterestVariable && (<small>+Euribor</small>)}</div>
        </div>}

        <div className={styles.DetailRow}>
          <div className={styles.Label}>Total Bonificaciones</div>
          <div className={styles.Value}>{totalBonus}%</div>
        </div>

        <div className={styles.DetailRow}>
          <div className={styles.Label}>Comisión</div>
          <div className={styles.Value}>{formatLocalCurrencyCustom(getComissionValue(bid.general_fees))}</div>
        </div>

        {<div className={styles.DetailRow}>
          <div className={styles.Label}>Cuota Mensual</div>
          <div className={styles.Value}>{formatLocalCurrencyCustom(bid.calculated.monthly)}</div>
        </div>}

        {/* {mode === "accepted" && <div className={styles.DetailRow}>
          <div className={styles.Label}>Valor Total de Intereses</div>
          <div className={styles.Value}>{formatLocalCurrencyCustom(getComissionValue(bid.general_fees))}</div>
        </div>} */}

        {mode === "accepted" && <div className={styles.DetailRow}>
          <div className={styles.Label}>Valor Total de Hipoteca</div>
          <div className={styles.Value}>{formatLocalCurrencyCustom(mortgage.mortgage_data.data.amount)}</div>
        </div>}

      </div>
      {mode === "await" && <div className={styles.BubbleFooter}>
        <div className={styles.Left}>
          <Button
            size="small"
            variant="contained"
            onClick={() => { setShowDetails(true) }}
          >
            Ver Oferta
          </Button>
        </div>
        <div className={styles.Right}>
          <BidderName bidder_type={bid.bidder.bidder_type} rating={bid.bidder.rating} /> {(mortgage.status === "active" || mortgage.status === "closed") && !isDisabled && <Checkbox value={bid.id} checked={isBidSelected} onChange={handleSelectBid} />}
        </div>
      </div>}

      {mode === "accepted" && <div className={styles.BubbleFooter}>
        <div className={styles.Left}>
          <Button
            size="small"
            variant="contained"
            onClick={() => { navigate(`/client/bid/${bid.id}`) }}
          >
            Ir a oferta
          </Button>
        </div>
        <div className={styles.Right}>
          <div className={styles.ProgressBar}>
            <div className={styles.Icon}>
              <TimelineIcon label={bid.timeline.current.action} className="" />
            </div>
            <div className={styles.Track}>
              <p className={styles.Caption}>{bid.timeline.current.label} {Number(bid.timeline.progress.completed).toFixed(0)}%</p>
              <div className={styles.Bar}>
                <span className={styles.Progress} style={{ width: bid.timeline.progress.completed }}></span>
              </div>
            </div>
          </div>
        </div>
      </div>}

      <CustomDialog open={showDetails} onClose={() => { setShowDetails(false) }}>
        <BidDetailsWithTabs bid={[bid]} mortgage={mortgage} details={true} />
      </CustomDialog>

      <Dialog
        open={showCancelDialog}
        onClose={closeCancelDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirmar acción
        </DialogTitle>
        <DialogContent>
          <small className={styles.CaptionDialog}>Estás realmente seguro de que quieres cancelar esta oferta? Esta acción es irreversible confirma solo si estás seguro.</small>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCancelDialog}>Cancelar</Button>
          <Button onClick={() => {
            onTrash(bid.id);
            closeCancelDialog();
          }} autoFocus>Aceptar</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

interface BidderNameProps {
  bidder_type: 'bank' | 'broker';
  rating: number;
}
export const BidderName = ({ bidder_type, rating }: BidderNameProps) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const handleTooltipClick = () => {
    setIsModalVisible(true);
  };

  const renderModalDialog = () =>
    isModalVisible && (
      <Dialog
        open={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: "10px", position: "relative" }, // Redondea bordes
        }}
      >
        {/* Botón de cierre (X) arriba a la derecha */}
        <IconButton
          onClick={() => setIsModalVisible(false)}
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
            color: "gray",
          }}
        >
          <Close />
        </IconButton>

        {/* Contenido del modal */}
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "center", color: "black" }}
          >
            <p>
              <span className={styles.highlight}>¿Qué significa la estrella? ⭐</span>
            </p>
            <p>
              La estrella indica que este <span className={styles.highlight}>licitador </span>
              tiene un <span className={styles.highlight}>excelente rendimiento </span>
              cerrando acuerdos con clientes.
            </p>
            <p>¡Su experiencia y trayectoria lo destacan frente al resto! 🚀</p>
            <p>
              <span className={styles.highlight}>Recuerda:</span> Puedes revisar los
              detalles de la oferta y elegir la que más te convenga. Aprovecha la
              experiencia de estos licitadores destacados para conseguir las mejores
              condiciones.
            </p>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );

  return (
    <div className={styles.Bidder}>{rating > 4 && <IconButton
      onClick={() => handleTooltipClick()}
      style={{
        marginRight: "-5px"
      }}
    >
      <Star className={`${styles.PulseStart} ${styles.Icon}`} />
    </IconButton>}
      <h5>{getBidderTypeInES(bidder_type)}</h5>
      {renderModalDialog()}
    </div>
  )
}