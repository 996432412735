import { useEffect, useRef, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContentText,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { getMortgage } from "services/api";
import { getCrmHeaderFields } from "services/api/crm.service";
import { BidSection, CustomDialog, TableSkeleton } from "shared/components";
import { useDialog } from "shared/hooks";
import { MortgageRes, TimeLineStep } from "shared/types/BaseApiResponse";
import EvalSection from "./components/EvalSection";
import PPSteppers from "./components/PPSteppers";
import usePPReducer, { defaultPPSteps } from "./usePPReducer";
import { DIALOGS } from "shared/constants/localStorage";
import dataStorageService from "services/api/dataStorage.service";
import CustomAlert from "shared/components/CustomAlert";
import { OMITS_STEPS } from "shared/constants/general";
import PPOffers from "./components/PPOffers";
import {
  getListOfBidsinMortgageId,
  getTimeLineBid,
} from "services/api/bids.service";
import { Bid } from "shared/types/Bid";
import InfoOfferts from "./components/InfoOfferts";
import PPBidFileSection from "./components/PPBidFileSection";
import TasadoraComponent from "./components/TasadoraComponent";
import useQuery from "shared/hooks/useQuery";
import { PP_ACTIVE_STEP } from "shared/constants/queryParams";

interface PrincipalPanelProps {
  m: MortgageRes | undefined;
  onUpdateData: (data: string) => void;
}

export default function PrincipalPanel({
  m,
  onUpdateData,
}: PrincipalPanelProps) {
  const qParams = useQuery();
  // state of stepper change
  // mortgage should or maybe came as a props
  // get bidder evaluation fields from mortgage evaluation
  // meter la mayoria de esto estado en un reducer? agregar contexto, aunque solo esta usando bidsection?
  const [ppState, ppDispatch] = usePPReducer();
  const { mortgage, activeStep, currStep, completedStep, steps, bids } =
    ppState;
  // const [steps, setSteps] = useState(defaultPPSteps);
  const stepDescRef = useRef<number>(0);
  const { isShowing, closeDialog, openDialog } = useDialog();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (m) {
      ppDispatch({ type: "SET_MORTGAGE", payload: m });
      ppDispatch({ type: "SET_BIDS", payload: m.bids });
      setIsFetching(false);
      const activeStepInQueryParams = qParams.get(PP_ACTIVE_STEP);
      if (activeStepInQueryParams) {
        ppDispatch({
          type: "SET_ACTIVE_STEP",
          payload: Number(activeStepInQueryParams),
        });
      }
    }
  }, [m]);

  useEffect(() => {
    // Step timeline
    if (mortgage && !mortgage.evaluation?.length) {
      getTimeLineBid("", mortgage.id)
        .then((res) => {
          if (res.data.results.length > 0) {
            const filterDefault: TimeLineStep[] = res.data.results;

            const stps = filterDefault.map((stp: TimeLineStep) => {
              let stepp = {
                step: {
                  description: stp.description ? stp.description : "",
                  description2: stp.description ? stp.description : "",
                  label: stp.label,
                  name: stp.action,
                },
              };
              return stepp;
            });

            ppDispatch({
              type: "PUSH_STEPS",
              payload: stps,
            });

            ppDispatch({
              type: "SET_STATE_STEPS",
              payload: {
                steps: filterDefault,
                mortgage: mortgage, // use 'mortgage' instead of 'mortage'
              },
            });
          }
        })
        .catch((err) => console.error({ err }));
    }
  }, [mortgage]);

  const refreshMortgageWithBid = (status: string) => {
    onUpdateData(status);

    /*   setIsFetching(true);
    if (mortgage) {
      getListOfBidsinMortgageId(mortgage.id, status)
        .then((res) => {
          let bids = res.data.results;
          ppDispatch({ type: "SET_MORTGAGE", payload: mortgage });
          ppDispatch({ type: "SET_BIDS", payload: bids });
          setIsFetching(false);
        })
        .catch((err) => console.error({ err }));
    } */
  };

  if (mortgage) {
    const offert =
      mortgage.bids.some(
        (bid) => bid.status === "accepted" || bid.status === "rejected"
      ) ||
      mortgage.bids_bank.some(
        (bid) => bid.status === "accepted" || bid.status === "rejected"
      ) ||
      mortgage.bids_broker.some(
        (bid) => bid.status === "accepted" || bid.status === "rejected"
      );

    if (
      (mortgage.status === "in_verification_accepted" ||
        mortgage.status === "closed_successfully") &&
      offert
    ) {
      return (
        <Stack spacing={3} id="principal-panel">
          <PPSteppers
            id="pp-steppers"
            activeStep={activeStep}
            currStep={currStep}
            alternativeLabel
            nonLinear
            steps={steps}
            stepCompleted={completedStep}
            BtnOnClick={(idx: number) => {
              // revisar si se puede cambiar vista o solo popup
              if (idx <= currStep) {
                ppDispatch({ type: "SET_ACTIVE_STEP", payload: idx });
              } else {
                // mostrar popup con descripcion
                stepDescRef.current = idx;
                openDialog();
              }
            }}
          />
          <PPOffers
            mortgageOfferts={bids}
            mortagage={mortgage}
            changeTabOffers={(status: Bid["status"]) => {
              refreshMortgageWithBid(status);
              setIsFetching(true);
            }}
            fetching={isFetching}
          />
        </Stack>
      );
    }
  }

  return (
    <Stack spacing={3} id="principal-panel">
      <PPSteppers
        id="pp-steppers"
        activeStep={activeStep}
        currStep={currStep}
        alternativeLabel
        nonLinear
        steps={steps}
        stepCompleted={completedStep}
        BtnOnClick={(idx: number) => {
          // revisar si se puede cambiar vista o solo popup
          if (idx <= currStep) {
            ppDispatch({ type: "SET_ACTIVE_STEP", payload: idx });
          } else {
            // mostrar popup con descripcion
            stepDescRef.current = idx;
            openDialog();
          }
        }}
      />
      {/* {!mortgage && (
        <TableSkeleton headColumns={5} bodyRows={5} bodyColumns={0} />
      )} */}

      {mortgage && [0, 1, 2].includes(activeStep) && steps && (
        <>
          <BidSection
            mortgage={mortgage}
            onSubmit={() => refreshMortgageWithBid("accepted")}
          />

          {/* <InfoOfferts /> */}
        </>
      )}
      {mortgage && activeStep === 3 && (
        <>
          <OnBidSelectedDialog />
          <PPBidFileSection mortgage={mortgage} />
          <Divider variant="middle" sx={{ margin: "1rem 0" }} />
          <div style={{ marginTop: "2.5rem" }}></div>
          <TasadoraComponent mortgage={mortgage} step={1} variant="doc" />
        </>
      )}

      {activeStep >= 4 &&
        mortgage &&
        (mortgage.status === "closed_successfully" ||
          mortgage.status === "paid") && (
          <EvalSection
            steps={steps}
            activeStep={activeStep}
            completedStep={completedStep}
            mortgage={mortgage}
          />
        )}
      {steps && steps[stepDescRef.current] && (
        <CustomDialog
          id="description dialog"
          open={isShowing}
          onClose={closeDialog}
          title={steps[stepDescRef.current].step?.label}
        >
          <Typography>
            {steps[stepDescRef.current].step?.description}
          </Typography>
        </CustomDialog>
      )}
    </Stack>
  );
}

export function OnBidSelectedDialog() {
  const { isShowing, closeDialog, openDialog } = useDialog();
  useEffect(() => {
    dataStorageService
      .getData(DIALOGS.CLIENT_SELECT_BID_CONGRATS)
      .catch((err) => {
        openDialog();
      });
  }, []);

  const handleClose = () => {
    // the key value dont matther cause we check with truthy and falsey 🏖️es
    // localStorage.setItem(DIALOGS.CLIENT_SELECT_BID_CONGRATS, "he/she do");
    dataStorageService.setData({
      key: DIALOGS.CLIENT_SELECT_BID_CONGRATS,
      payload: "he/she do",
    });
    closeDialog();
  };

  return (
    <CustomDialog
      open={isShowing}
      onClose={closeDialog}
      id="confirm-bid-dialog"
    >
      <Stack gap={2}>
        <DialogContentText
          color="primary"
          variant="h5"
          textAlign="center"
          sx={{ fontWeight: "bold" }}
        >
          ¡Enhorabuena!
        </DialogContentText>
        <DialogContentText variant="body1" textAlign="center">
          ¡Has elegido una oferta! Es hora de cargar toda la documentación para
          poder avanzar con la formalización de tu hipoteca.{" "}
          <Typography color="primary" component="span">
            Una vez cargada la documentación, nuestro equipo la validará en
            menos de 48hs (días laborales) y el banco/bróker se contactará
            contigo para seguir con el proceso.
          </Typography>
        </DialogContentText>
        <DialogActions sx={{ justifyContent: "space-around" }}>
          <Button variant="contained" onClick={handleClose}>
            De acuerdo
          </Button>
        </DialogActions>
      </Stack>
    </CustomDialog>
  );
}
