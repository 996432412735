import { FunctionComponent, ReactComponentElement, useEffect } from "react";

import {
  Container,
  Grid,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListSubheader,
  Box,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

import { MainClientLayout } from "../../../shared/layouts";
import { PageTitle } from "../../../shared/components";

// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PolicyIcon from "@mui/icons-material/Policy";
import CookieIcon from "@mui/icons-material/Cookie";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useLocalStorage, useProfile } from "../../../shared/hooks";
import { useAuthenticated } from "../../../shared/hooks";
import { sendTagEvent, posthogUtils } from "utils/analytics";
import styles from "./styles.module.scss";
import { format } from "date-fns";
import { ClientProfile } from "shared/types/Profile";
import ProfileAvatarSkeleton from "shared/components/CustomSkeleton/ProfileAvatar";
import { delTokenSSID } from "utils/axios";
const Profile: FunctionComponent<{}> = (): ReactComponentElement<any> => {
  const [role] = useLocalStorage("role");
  const { profile, isFetching } = useProfile<ClientProfile>();
  const name = `${profile?.firstname} ${profile?.lastname}`;
  const email = profile?.user;
  const location = useLocation();
  const navigate = useNavigate();


  useAuthenticated((allow) => {
    if (!allow) navigate("/sign-in");
  }, "client");

  const logout = () => {
    posthogUtils.resetUser();
    localStorage.removeItem("access_token");
    localStorage.clear();
    delTokenSSID();
    window.location.href = "/sign-in";
  };

  useEffect(() => {
    if (profile) {
      sendTagEvent({
        event: "page_view",
        page_category: location.search,
        page_location: location.pathname, // URL
        user_type: role,
        user_id: profile.id,
        user_registration_date: format(new Date(), "MM/dd/yyyy"),
        user_login_status: "active",
      });
    }
  }, [profile]);

  return (
    <MainClientLayout>
      <>
        <Container>
          <Grid container spacing={2} marginTop={0}>
            <Grid item xs={12} md={12}>
              <PageTitle title="Mi Perfil" />
            </Grid>

            <Grid item xs={12} md={12}>
              <Box className={styles.HeaderBox}>
                {isFetching ? (
                  <ProfileAvatarSkeleton />
                ) : (
                  <>
                    <Box
                      className={styles.HeaderItem}
                      sx={{ marginRight: "1rem" }}
                    >
                      <Avatar
                        src="/assets/img/avatar/a9.svg"
                        sx={{ width: 70, height: 70 }}
                      />
                    </Box>
                    <Box className={styles.HeaderItem}>
                      <Typography variant="h6">{name}</Typography>
                      <Typography variant="caption">{email}</Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Divider component="hr" />
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <List
                subheader={
                  <ListSubheader className={styles.SubTitle}>
                    GENERALES
                  </ListSubheader>
                }
                className={styles.ListGroup}
                sx={{ marginTop: "1.5rem" }}
              >
                {/* <ListItem button className={styles.ListItem}>
                  <ListItemText primary="Información personal" />
                  <PersonOutlinedIcon className={styles.ListIcon} />
                </ListItem> */}
                {/* <Divider component="li" /> */}
                <ListItem
                  button
                  onClick={() => navigate("/client/profile/changepassword")}
                  className={styles.ListItem}
                >
                  <ListItemText primary="Cambiar contraseña" />
                  <LockOutlinedIcon className={styles.ListIcon} />
                </ListItem>
              </List>

              <List
                subheader={
                  <ListSubheader className={styles.SubTitle}>
                    ACERCA DE
                  </ListSubheader>
                }
                className={styles.ListGroup}
              >
                <ListItem
                  className={styles.ListItem}
                  button
                  component="a"
                  href="https://colibid.com/terminos-y-condiciones/"
                  target="n_blank"
                >
                  <ListItemText primary="Términos y Condiciones" />
                  <InfoOutlinedIcon className={styles.ListIcon} />
                </ListItem>
                <Divider component="li" />
                <ListItem
                  className={styles.ListItem}
                  button
                  component="a"
                  href="https://colibid.com/privacy-policy/"
                  target="n_blank"
                >
                  <ListItemText primary="Política de Privacidad" />
                  <PolicyIcon className={styles.ListIcon} />
                </ListItem>
                <Divider component="li" />
                <ListItem
                  className={styles.ListItem}
                  button
                  component="a"
                  href="https://colibid.com/politica-de-cookies/"
                  target="n_blank"
                >
                  <ListItemText primary="Política de Cookies" />
                  <CookieIcon className={styles.ListIcon} />
                </ListItem>
                <Divider component="li" />
                <ListItem
                  className={styles.ListItem}
                  button
                  component="a"
                  href="https://colibid.com/preguntas-frecuentes/"
                  target="n_blank"
                >
                  <ListItemText primary="Preguntas Frecuentes" />
                  <ContactSupportOutlinedIcon className={styles.ListIcon} />
                </ListItem>
                <Divider component="li" />
                <ListItem
                  button
                  onClick={() => logout()}
                  className={styles.ListItem}
                >
                  <ListItemText primary="Cerrar Sesión" />
                  <LogoutOutlinedIcon className={styles.ListIcon} />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </>
    </MainClientLayout>
  );
};

export default Profile;
