import { intervalToDuration, parseISO } from "date-fns";
import _ from "lodash";
import { EMPLOYMENT_SITUATIONS } from "shared/components/MortgageForm/utils";
import logger from "./logger";

/**
 * Get name of file
 *
 * @param {string} code
 * @returns
 */
export const getFileName = (code) => {
  const types = {
    Doc_laboral: "Vida laboral",
    Doc_cotizacion: "Bases de cotización SS",
    Doc_renta: "Renta",
    Doc_CIRBE: "CIRBE",
    Doc_IS: "Impuesto Sociedades",
    Doc_situacionSS: "Situación SS",
    Doc_situacionAEAT: "Deudas AEAT",
    Doc_347: "Modelo 347",
    Doc_111: "Modelo 111",
    Doc_130: "Modelo 130",
    Doc_190: "Modelo 190",
    Doc_303: "Modelo 303",
    Doc_390: "Modelo 390",
    Doc_200: "Modelo 200",
    Doc_existingMortgage: "Declaración de la hipoteca existente",
    coli_contract: "Contrato",
    coli_invoice: "Factura",
    coli_dni_front: "DNI anverso",
    coli_dni_back: "DNI reverso",
    coli_notasimple: "Nota Simple",
    coli_last_3_file: "Última nómina",
    coli_dni_front_user2: "DNI anverso de persona conjunta",
    coli_dni_back_user2: "DNI reverso de persona conjunta",
    coli_emp_contract: "Contrato de trabajo",
    coli_account_state: "Extracto de cuenta bancaria de los últimos 6 meses",
    coli_oferta_compra: "Oferta de compra",
    coli_job_contract: "Contrato de trabajo",
    coli_certificate: "Certificado Digital",
    Doc_laboral_user2: "Vida laboral de persona conjunta",
    Doc_cotizacion_user2: "Bases de cotización SS de persona conjunta",
    Doc_renta_user2: "Renta de persona conjunta",
    Doc_CIRBE_user2: "CIRBE de persona conjunta",
    Doc_IS_user2: "Impuesto Sociedades de persona conjunta",
    Doc_situacionSS_user2: "Situación SS de persona conjunta",
    Doc_situacionAEAT_user2: "Deudas AEAT de persona conjunta",
    Doc_347_user2: "Modelo 347 de persona conjunta",
    Doc_111_user2: "Modelo 111 de persona conjunta",
    Doc_130_user2: "Modelo 130 de persona conjunta",
    Doc_190_user2: "Modelo 190 de persona conjunta",
    Doc_303_user2: "Modelo 303 de persona conjunta",
    Doc_390_user2: "Modelo 390 de persona conjunta",
    Doc_200_user2: "Modelo 200 de persona conjunta",
    Doc_existingMortgage_user2:
      "Declaración de la hipoteca existente de persona conjunta",
    coli_contract_user2: "Contrato de persona conjunta",
    coli_invoice_user2: "Factura de persona conjunta",
    coli_notasimple_user2: "Nota Simple de persona conjunta",
    coli_last_3_file_user2: "Última nómina de persona conjunta",
    coli_emp_contract_user2: "Contrato de trabajo de persona conjunta",
    coli_account_state_user2:
      "Extracto de cuenta bancaria de los últimos 6 meses de persona conjunta",
    coli_oferta_compra_user2: "Oferta de compra de persona conjunta",
    coli_job_contract_user2: "Contrato de trabajo de persona conjunta",
    coli_certificate_user2: "Certificado Digital de persona conjunta",
  };

  return types[code] || "Documento en verificación";
};

/**
 * Get required documents by situation work
 *
 * @returns
 */
export const requiredDocuments = () => {
  const documents = {
    asalariado: [
      {
        description: "DNI anverso",
        code: "coli_dni_front",
      },
      {
        description: "DNI reverso",
        code: "coli_dni_back",
      },
      {
        description: "Nota Simple",
        code: "coli_notasimple",
      },
      {
        description: "Certificado Digital",
        code: "coli_certificate",
      },
      {
        description: "Vida laboral",
        code: "Doc_laboral",
      },
      {
        description: "Bases de cotización SS",
        code: "Doc_cotizacion",
      },
      {
        description: "Renta",
        code: "Doc_renta",
      },
      {
        description: "CIRBE",
        code: "Doc_CIRBE",
      },
      {
        description: "Deudas AEAT",
        code: "Doc_situacionAEAT",
      },
      {
        description: "Situación SS",
        code: "Doc_situacionSS",
      },
    ],
    autonomo: [
      {
        description: "DNI anverso",
        code: "coli_dni_front",
      },
      {
        description: "DNI reverso",
        code: "coli_dni_back",
      },
      {
        description: "Nota Simple",
        code: "coli_notasimple",
      },
      {
        description: "Vida laboral",
        code: "Doc_laboral",
      },
      {
        description: "Certificado Digital",
        code: "coli_certificate",
      },
      {
        description: "Bases de cotización SS",
        code: "Doc_cotizacion",
      },
      {
        description: "Renta",
        code: "Doc_renta",
      },
      {
        description: "CIRBE",
        code: "Doc_CIRBE",
      },
      {
        description: "Deudas AEAT",
        code: "Doc_situacionAEAT",
      },
      {
        description: "Situación SS",
        code: "Doc_situacionSS",
      },
      {
        description: "Modelo 347",
        code: "Doc_347",
      },
      {
        description: "Modelo 390",
        code: "Doc_390",
      },
      {
        description: "Modelo 190",
        code: "Doc_190",
      },
      {
        description: "Modelo 303",
        code: "Doc_303",
      },
      {
        description: "Modelo 130",
        code: "Doc_130",
      },
      {
        description: "Modelo 111",
        code: "Doc_111",
      },
      {
        description: "Modelo 200",
        code: "Doc_200",
      },
    ],
  };

  return documents;
};

/**
 * Get employment situation
 *
 * @param {string | number | undefined} code
 * @returns
 */
export const getEmploymentSituation = (code) => {
  if (code)
    return (
      EMPLOYMENT_SITUATIONS.find((e) => e.id === String(code))?.description ||
      "Situación laboral no definida"
    );
  return "";
};

/**
 * Get month of year
 *
 * @param {string} number
 * @returns
 */
export const getYearMonth = (number) => {
  const months = {
    "01": "Enero",
    "02": "Febrero",
    "03": "Marzo",
    "04": "Abril",
    "05": "Mayo",
    "06": "Junio",
    "07": "Julio",
    "08": "Agosto",
    "09": "Septiembre",
    10: "Octubre",
    11: "Noviembre",
    12: "Diciembre",
  };

  return months[number] || "--";
};

/**
 * Get user professions
 *
 * @param {string} code
 * @returns
 */
export const getUserProfessions = () => {
  const professions = [
    {
      id: "1",
      description: "Gestión",
    },
    {
      id: "3",
      description: "Informática y Matemáticas",
    },
    {
      id: "4",
      description: "Arquitectura e ingeniería",
    },
    {
      id: "10",
      description: "Profesionales y técnicos de la salud",
    },
    {
      id: "14",
      description: "Limpieza y mantenimiento de edificios y terrenos",
    },
    {
      id: "2",
      description: "Operaciones comerciales y financieras",
    },
    {
      id: "11",
      description: "Apoyo sanitario",
    },
    {
      id: "6",
      description: "Servicios sociales y comunitarios",
    },
    {
      id: "16",
      description: "Ventas y afines",
    },
    {
      id: "5",
      description: "Ciencias de la vida, físicas y sociales",
    },
    {
      id: "7",
      description: "Legal",
    },
    {
      id: "8",
      description: "Instrucción Educativa y Biblioteca",
    },
    {
      id: "9",
      description:
        "Artes, diseño, entretenimiento, deportes y medios de comunicación",
    },
    {
      id: "12",
      description: "Servicios de protección",
    },
    {
      id: "13",
      description: "Preparación y servicio de alimentos",
    },
    {
      id: "15",
      description: "Cuidado y servicio personal",
    },
    {
      id: "17",
      description: "Apoyo administrativo y de oficina",
    },
    {
      id: "18",
      description: "Agricultura, Pesca y Silvicultura",
    },
    {
      id: "19",
      description: "Construcción y Extracción",
    },
    {
      id: "20",
      description: "Instalación, mantenimiento y reparación",
    },
    {
      id: "21",
      description: "Producción",
    },
    {
      id: "22",
      description: "Transporte y movimiento de materiales",
    },
    {
      id: "23",
      description: "Otro",
    },
  ];

  return professions;
};

export const getRequestType = () => {
  const type = [
    { id: 1, description: "Individual" },
    { id: 2, description: "Conjunta" },
  ];
  return type;
};
/**
 * Format duration of end date
 *
 * @param {string} date
 * @returns
 */
export const getDuration = (date) => {
  const duration = intervalToDuration({
    start: parseISO(date),
    end: new Date(),
  });

  return `${duration.days}d ${duration.hours}h ${duration.minutes}m`;
};

export const statusChecker = (status) => {
  switch (status) {
    case "created":
      status = "Pendiente de verificación";
      break;
    case "active":
      status = "Activa";
      break;
    case "closed":
      status = "Finalizada";
      break;
    case "closed_successfully":
      status = "Completada exitosamente";
      break;
    case "waiting_for_response":
      status = "A la espera por confirmación del ofertante";
      break;
    case "in_verification":
      status = "En verificación";
      break;
    case "in_verification_accepted":
      status = "Aceptada pero pendiente de verificación de documentos";
      break;
    case "expired":
      status = "Expirada";
      break;
    case "paid":
      status = "Completada exitosamente";
      break;
    case "collapsed":
      status = "Cancelada";
      break;
    case "draft":
      status = "Aplicación en borrador";
      break;
    default:
      status = "No asignado";
      break;
  }
  return status;
};

export const getApplicationType = (mode) => {
  switch (mode) {
    case 1:
      mode = "Nueva hipoteca";
      break;
    case 2:
      mode = "Mejora de hipoteca";
      break;
    case 3:
      mode = "Ampliación de capital";
      break;
    case 4:
      mode = "Autopromotor";
      break;
    default:
      break;
  }
  return mode;
};
export const getInterestType = (value) => {
  //Change to 🇪🇸 the value of the label for the first period in mixed interest type
  if (value === "initial") value = "inicial";
  return _.capitalize(value);
};

function validarNumeroDecimal(numero) {
  // Expresión regular que valida el número decimal
  const regex = /^(([1-9]\d*)|0)(\.\d+)?$/;

  // Devolvemos true si el número coincide con la expresión regular, y false en caso contrario
  return regex.test(numero);
}

export const getConsultingType = (value) => {
  switch (value) {
    case 1:
      return "Por firma ante notaria";
    case 2:
      return "Pago por adelantado";
    case 3:
      return "Pago incluido en la hipoteca";
    case 4:
      return "Por firma de FEIN";
    default:
      return "No especificado";
  }
};

export const getConsultingMessageType = (value) => {
  switch (value) {
    case 1:
      value =
        "Los honorarios del asesor, se abonan en el momento de la firma de la hipoteca ante el notario.";
      break;
    case 2:
      value = "Los honorarios del asesor, se realizan por adelantado.";
      break;
    case 3:
      value =
        "Los honorarios del asesor, se incluyen dentro del pago de la hipoteca.";
      break;
    case 4:
      value =
        "Los honorarios del asesor, se abonarán tras la aceptación de la operación por parte de la entidad bancaria.";
      break;
    default:
      value = "No especificado";
      break;
  }
  return value;
};

export const getMonthlyExpense = (expenses) => {
  let total = 0;
  if (expenses !== null) {
    // eslint-disable-next-line array-callback-return
    expenses?.map((e) => {
      total += Number(e?.debt_monthly);
    });
  }

  return total;
};

export const getBalance = (income, expenses) => {
  let balance = income - expenses;
  // balance = `${balance}€`;
  return balance;
};

export const censureChars = (arrStr) => {
  try {
    for (let c of arrStr) {
      if (c !== " ") {
        arrStr = arrStr.replace(c, "X");
      }
    }
  } catch (e) {
    console.log(e);
  }
  return arrStr;
};

export const validateConditions = (conditions) => {
  var setinel = false;
  try {
    for (let condition of conditions) {
      if (condition.allow === true) {
        setinel = true;
        return setinel;
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const trimFilesUpload = (url) => {
  const parts = url.split("/");
  const fileName = decodeURI(parts[parts.length - 1]);

  return fileName;
};

export const formatNumbers = (number) => {
  var formatedNumber = number.replace(".", "");
  return formatedNumber;
};

/**
 * return a formated number to a currency
 * @param {number | string} value
 * @param {Intl.NumberFormatOptions} options
 * @returns currency formated string
 */
export const formatCompactCurrency = (value, options = {}) => {
  return new Intl.NumberFormat("gb-EB", {
    style: "currency",
    currency: "eur",
    notation: "compact",
    compactDisplay: "short",
    ...options,
  }).format(value);
};

export const formatCurrency = (amount) => {
  if (Number(amount) === 0) return "";

  var formated = formatNumberToCurrency(String(amount));
  if (formated === undefined || "" || null) {
    formated = 0;
  }

  formated = new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(Number(amount));

  return formated;
};

export const formatLocalCurrency = (amount) => {
  const formated = new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0,
    currencyDisplay: "symbol",
  }).format(Number(amount));
  return formated;
};

export const formatLocalCurrencyCustom = (amount) => {
  const number = Number(amount);
  
  // Formateamos el número con separadores de miles (.)
  const formattedNumber = number
    .toLocaleString("es-AR", { maximumFractionDigits: 0 })
    .replace(/\s/g, '.');  // Reemplaza espacios por puntos (por si acaso)

  // Añadimos el símbolo del euro al final
  return `${formattedNumber} €`;
};


export const formatNumber = (n) => {
  return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatNumberToCurrency = (amount) => {
  if (amount === "") {
    return;
  }

  if (amount.indexOf(".") >= 0) {
    var pos = amount.indexOf(".");
    var left = amount.substring(0, pos);
    var right = amount.substring(pos);

    left = formatNumber(left);
    right = formatNumber(right);

    right = right.substring(0, 2);
    amount = left + "." + right;
  } else {
    amount = formatNumber(amount);
  }

  return amount;
};

export const formatToRate = (rate) => {
  rate = rate.replace(/[^\d.]/g, "");

  if (rate.indexOf(".") > 0) {
    var pos = rate.indexOf(".");
    var left = rate.substring(0, pos).substring(0, 2);
    var right =
      rate.substring(pos).substring(0, 3).slice(-1).match("^[0-9]*$") === null
        ? rate.substring(pos).substring(0, 1)
        : rate.substring(pos).substring(0, 3);

    rate = `${left}${right}`;
  }
  if (rate.indexOf(".") === 0 && rate.length >= 3) {
    rate = "";
  }
  if (parseFloat(rate) <= 0 && rate.length > 3) {
    rate = "";
  }

  if (rate.length > 2) {
    rate = validarNumeroDecimal(rate) ? rate : rate.substring(1);
  }

  return rate;
};

export const userHomeURL = (role) => {
  switch (role) {
    case "client":
      return "/client/home" + window.location.search;

    case "broker":
      return "/broker/applications" + window.location.search;

    case "realstate":
      return "/referrals" + window.location.search;
    
    case "partner":
      return "/partner" + window.location.search;

    default:
      return "" + window.location.search;
  }
};

export const splitName = (name) => {
  try {
    let trim_name = name.split(" ")[0];
    let trim_lastname = name.split(" ")[1].charAt(0);
    trim_name = `${trim_name} ${trim_lastname}.`;
    return trim_name;
  } catch (e) {
    console.log(e);
  }
};

export const validateSpanishDNI = (doc) => {
  let hash, lc, letters, rest;
  var regex = /^[XYZ]?\d{5,8}[A-Z]$/i;

  if (regex.test(doc) === true) {
    letters = "TRWAGMYFPDXBNJZSQVHLCKET";
    hash = doc
      .toUpperCase()
      .slice(0, -1)
      .replace("X", 0)
      .replace("Y", 1)
      .replace("Z", 2);
    lc = doc.toUpperCase().slice(doc.length - 1);
    rest = hash % 23;

    if (letters[rest] !== lc) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const typeSpanishDNI = (doc) => {
  if (["X", "Y", "Z"].indexOf(doc.toUpperCase().charAt(0)) !== -1) {
    return "NIE";
  }

  return "DNI";
};

export const getValueInObjectWithDotNotation = (a, notation) => {
  var arr = notation.split(".");

  while (arr.length && (a = a[arr.shift()]));

  return a;
};

export const getTotalComissionDisscount = (fee, net_fee, percentage) => {
  let per = percentage ? percentage : 0.15;
  let disccount = fee * per;
  let financed_net_fee = net_fee - disccount;

  return parseFloat(financed_net_fee).toFixed(2);
};

export const getMonthlyComission = (value, period) => {
  let result = value / period;
  return `${result.toFixed(0)}€ `;
};

export const getLabelFromOptions = (value, arrOfOptions) => {
  return arrOfOptions.find((o) => o.value === value)?.label || value;
};

export const asignarColor = (arr) => {
  let colorCount = {};
  let assignedColors = {};

  // Contamos las veces que aparece cada entidad
  arr.forEach((item) => {
    if (colorCount[item.entity]) {
      colorCount[item.entity]++;
    } else {
      colorCount[item.entity] = 1;
    }
  });

  const startColor = { R: 0xef, G: 0xef, B: 0xfb, A: 0x80 }; // 0x80 es 50% de transparencia en hexadecimal
  const endColor = { R: 0x2e, G: 0x2e, B: 0xc0, A: 0x80 }; // 0x80 es 50% de transparencia en hexadecimal
  let uniqueEntities = Object.keys(colorCount).length;

  // Asignamos un color a cada entidad
  let i = 1;
  for (const entity in colorCount) {
    if (colorCount[entity] >= 1) {
      let t = i / uniqueEntities;
      let R = Math.round(startColor.R + t * (endColor.R - startColor.R));
      let G = Math.round(startColor.G + t * (endColor.G - startColor.G));
      let B = Math.round(startColor.B + t * (endColor.B - startColor.B));
      let A = startColor.A; // Siempre será 0x80 en este caso
      assignedColors[entity] = `#${R.toString(16).padStart(2, "0")}${G.toString(
        16
      ).padStart(2, "0")}${B.toString(16).padStart(2, "0")}${A.toString(
        16
      ).padStart(2, "0")}`;
      i++;
    } else {
      assignedColors[entity] = "#FFFFFF80"; // Blanco con 50% de transparencia
    }
  }

  // Agregamos el color a cada objeto del arreglo
  arr.forEach((item) => {
    item.color = assignedColors[item.entity];
  });

  return arr;
};

export const generarCadenaAleatoria = (longitud) => {
  const caracteres = "0123456789abcdefghijklmnopqrstuvwxyz";
  let resultado = "";

  for (let i = 0; i < longitud; i++) {
    const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
    resultado += caracteres[indiceAleatorio];
  }

  return resultado;
};
export const getTasadoraDescription = (name) => {
  return name === "Valmesa INC"
    ? "es una tasadora que se se encuentra en el mercado hace 35 años y tiene presencia en todo España"
    : name === "gloval"
    ? "es una firma líder en el el sector de tasaciones , con más de 70 años de experiencia. ¡Opera en toda España! "
    : name === "tinsa"
    ? "es una empresa líder de valoración inmobiliaria en España hace más de 3 años"
    : "";
};

export const formatRateInterest = (cadena) => {
  if (typeof cadena !== 'string' || cadena === null || cadena === undefined) {
    return cadena ? cadena : "";
  }

  return cadena.replace(/,/g, ".");
};


function validarPropiedades(errores, objeto, propiedades) {
  propiedades.forEach((prop) => {
    if (!objeto[prop]) errores.push(`${prop}`);
  });
}

export function validarObjeto(obj) {
  let errores = [];

  // Propiedades requeridas del usuario
  const userProps = [
    "name",
    "surname",
    "birthdate",
    "dni",
    "type_dni",
    "email",
    "phone",
  ];
  const user = obj.user;
  userProps.forEach((prop) => {
    if (!user[prop]) errores.push(`user.${prop}`);
  });

  // Si mortgage.mode es igual a 1, validar las demás propiedades
  if (obj.mortgage) {
    switch (obj.mortgage.mode) {
      case 1:
        validarPropiedades(errores, obj.data, [
          "mortgage_rate",
          "amount",
          "interest_type",
          "tax_and_expenses",
          "debtRatio",
        ]);
        validarPropiedades(errores, obj.property, [
          // "area",
          "value",
          "type",
          "province",
          "community",
          "city",
          "zipcode",
          "address",
          "house_situation",
        ]);
        break;
      case 2:
        validarPropiedades(errores, obj.data, [
          "curr_original_amount",
          "mortgage_rate",
          "curr_mortgage_init_date",
          "interest_type_remortgage",
          "pending_amount",
          "interest_rate",
          "interest_type",
          "amount",
          "remortgage_laps_years",
          "debtRatio",
        ]);
        break;
      case 3:
        validarPropiedades(errores, obj.data, [
          "mortgage_rate",
          "curr_mortgage_init_date",
          "interest_type_remortgage",
          "pending_amount",
          "interest_rate",
          "interest_type",
          "amount",
          "remortgage_laps_years",
          "debtRatio",
        ]);
        break;
      case 4:
        validarPropiedades(errores, obj.data, [
          "interest_type",
          "period",
          "mortgage_rate",
          "amount",
          "tax_and_expenses",
          "debtRatio",
        ]);
        break;
      default:
        errores.push("mortgage.mode tiene un valor inválido");
    }
  } else {
    errores.push("mortgage.mode es necesario");
  }
  if (errores.length > 0) {
    logger.error(errores)
    return false
  };
  return true;
}
