import {
  BaseApiSuccessRes,
  MortgagePartnerAgencies,
} from "shared/types/BaseApiResponse";
import {
  PostMortgageData
} from "shared/types/postMortgageData";
import axios from "../../utils/axios";

export interface addContact {
  first_name: string;
  last_name: string;
  preferred_time: string;
  phone: string;
  email: string;
}
export const partnerAddContact = (user: addContact) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/partners/agents/contact/create`,
    user
  );
};

export const partnerApplyMortgage = (data: PostMortgageData) => {
  return axios.post<BaseApiSuccessRes<MortgagePartnerAgencies>>(
    `${process.env.REACT_APP_API_URL}/partners/agents/apply`,
    data
  );
};
interface GetListClientParams {
  itemsPerPage: number;
  page: number;
}
export const getListClient = (params: GetListClientParams) => {
  const { itemsPerPage, page } = params;
  return axios.get<BaseApiSuccessRes<Array<MortgagePartnerAgencies>>>(
    `${process.env.REACT_APP_API_URL}/partners/agents/contact/list`,
    {
      params: {
        ipp: itemsPerPage,
        p: page,
      },
    }
  );
};

export const leadCheck = (email: string) => {
  return axios.get<BaseApiSuccessRes<{}>>(
    `${process.env.REACT_APP_API_URL}/partners/agents/lead/check`,
    {
      params: { email },
    }
  );
};

export const authLead = (lid: string) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/partners/agents/lead/auth?l=${lid}`);
};
