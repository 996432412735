import AlarmIcon from "@mui/icons-material/Alarm";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { parseISO } from "date-fns";
import _ from "lodash";
import { Dispatch, useState, useEffect } from "react";
import Countdown from "react-countdown";
import { customerAcceptBid } from "services/api";
import { CustomDialog, WithTooltip } from "shared/components";
import useBidReducer, {
  BidAction,
  BidReducerState,
} from "shared/components/BidSection/useBidReducer";
import { useDialog } from "shared/hooks";
import { MortgageRes } from "shared/types/BaseApiResponse";
import { dateExpired } from "utils";
import BidDetailsWithTabs from "./components/BidDetailsWithTabs";
import BidTable from "./components/BidTable";
import BidBubbles from "./components/BidBubbles";
import { customerAcceptBids } from "services/api/bids.service";
import styles from "./styles.module.scss";

type Props = { mortgage: MortgageRes; onSubmit: () => void };

export default function BidSection({ mortgage, onSubmit }: Props) {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [bidsState, bidsDispatch] = useBidReducer({ mortgage });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [moreInfo, setMoreInfo] = useState(false);

  const handleTooltipClick = () => {
    setIsModalVisible(true);
  };
  // const { bidSubmitted } = bidsState;

  const selectHandler = (bid: string) => {
    bidsDispatch({ type: "SELECT_BID", payload: bid });
  };

  // const handleTooltipClickClose = () => {
  //   setIsModalVisible(false);
  //   setMoreInfo(true);
  // };

  return (
    <>
      <br />
      <BidBubbles mortgage={mortgage} onSelect={selectHandler} bidsState={bidsState} />
      <BtnWithTooltip
        bidsDispatch={bidsDispatch}
        bidsState={bidsState}
        onFinish={onSubmit}
        onClickTooltip={handleTooltipClick}
      />
      <br /><br /><br />

     
      {/* <Paper
        sx={{
          padding: "2rem",
        }}
        id="bid-section"
        component="section"
      >
        {isSmallScreen ? (
          <Stack spacing={2}>
            <BidCountDown mortgage={mortgage} bidSubmitted={bidSubmitted} />
            <TitleWithTooltip />
            <BidTable
              onSelect={selectHandler}
              bidsState={bidsState}
              isSmallScreen={isSmallScreen}
            />
            <BtnWithTooltip
              bidsDispatch={bidsDispatch}
              bidsState={bidsState}
              onFinish={onSubmit}
              onClickTooltip={handleTooltipClick}
            />
          </Stack>
        ) : (
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between">
              <TitleWithTooltip />
              <BidCountDown mortgage={mortgage} bidSubmitted={bidSubmitted} />
              <BtnWithTooltip
                bidsDispatch={bidsDispatch}
                bidsState={bidsState}
                onFinish={onSubmit}
                onClickTooltip={handleTooltipClick}
              />
            </Stack>
            <BidTable
              onSelect={selectHandler}
              bidsState={bidsState}
              isSmallScreen={isSmallScreen}
            />
          </Stack>
        )}
      </Paper> */}

      {/* <Dialog
        open={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "center", color: "black" }}
          >
            <p>
              Las ofertas están catalogadas por colores, dependiendo de la
              entidad que hay detrás de ellas.
            </p>
            <p>
              Puedes seleccionar tantas ofertas de <span className={styles.higlight}>distintos bancos</span> como quieras pero <span className={styles.higlight}> solo una de bróker </span>
              :)
            </p>
            <p>
              Recuerda, ¡aceptar ofertas no compromete a nada aún! No tengas
              miedo a darle si te gusta lo que ves. ;)
            </p>

            <p>
              <span className={styles.higlight}> #coliconsejo: </span> elige
              cuanto antes para que la inestabilidad del mercado no haga cambiar
              las condiciones.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "space-evenly", textAlign: "center" }}
        >
          <Button
            sx={{
              color: "black",
              textDecoration: "underline",
              fontSize: "0.6rem",
            }}
            onClick={handleTooltipClickClose}
          >
            Quiero saber más
          </Button>
          <Button variant="contained" onClick={() => setIsModalVisible(false)}>
            Ok
          </Button>
        </DialogActions>
      </Dialog> */}

      <Dialog
        open={moreInfo}
        onClose={() => setMoreInfo(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "center" }}
          >
            <p>
              Colibid permite a bancos y brókers enviar múltiples ofertas. Dado
              que{" "}
              <span className={styles.higlight}>
                operamos a nivel nacional y las oficinas bancarias son
                independientes{" "}
              </span>
              , es posible que diferentes oficinas o brókers de un mismo banco
              envíen ofertas distintas a los mismos perfiles.
            </p>

            <p>
              Es por ello, que puedes recibir varias ofertas de la misma
              entidad.{" "}
              <span className={styles.higlight}>
                No obstante, no es posible continuar el proceso con varias
                ofertas en paralelo dentro del mismo banco, ¡pero sí con otros!
              </span>
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button variant="contained" onClick={() => setMoreInfo(false)}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

// Component section

function BidCountDown({
  mortgage,
  bidSubmitted,
}: {
  mortgage: MortgageRes;
  bidSubmitted: BidReducerState["bidSubmitted"];
}) {
  // if (bidSubmitted || !mortgage.bids_count) return null;
  const endDate =
    mortgage.status === "closed"
      ? dateExpired(mortgage.ends)
      : parseISO(mortgage.ends);
  return (
    <Stack alignItems="center" id="bid-count-down">
      <Typography variant="body2">
        Tiempo restante para elegir una oferta:
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="center"
      >
        <AlarmIcon color="primary" />
        <Countdown
          date={endDate}
          renderer={({ days, hours, minutes, seconds }) => (
            <Typography color="#33CCCC">
              {days}d : {hours}h : {minutes}m : {seconds}s
            </Typography>
          )}
        />
      </Stack>
    </Stack>
  );
}
const BtnWithTooltip = ({
  bidsDispatch,
  bidsState,
  onFinish,
  onClickTooltip,
}: {
  bidsState: BidReducerState;
  bidsDispatch: Dispatch<BidAction>;
  onFinish: () => void;
  onClickTooltip: () => void;
}) => {
  const { bidSubmitted } = bidsState;
  if (bidSubmitted) return null;

  return (
    // <ConfirmBidDialog
    //     bidsState={bidsState}
    //     bidsDispatch={bidsDispatch}
    //     onFinish={onFinish}
    //   />
    <WithTooltip title="Haz click para avanzar y aceptar las ofertas seleccionadas, debes seleccionar al menos una oferta." onClick={onClickTooltip}>
      <ConfirmBidDialog
        bidsState={bidsState}
        bidsDispatch={bidsDispatch}
        onFinish={onFinish}
      />
    </WithTooltip>
  );
};

const TitleWithTooltip = () => {
  return (
    <WithTooltip
      title='Aquí podrás ver todas las ofertas de subasta por tu hipoteca. Recuerda que no verás la información de la entidad hasta no  hayas aceptado una oferta y cargado la documentación (¡y lo mismo ellos de tí!) por protección de datos.
      Nuestro #coliconsejo es que utilices la columna de "Valor total préstamo" como medidor principal. Al fin y al cabo, lo que más nos interesa es saber cuánto vamos a pagar en total por la hipoteca, ¿no?
      Haz clic en cada oferta para más información.'
      position="right"
      justifyContent="flex-end"
    >
      <Typography sx={{ backgroundColor: "#D9F6EF" }} fontWeight="bold">
        Elige todas las ofertas que te gusten
      </Typography>
    </WithTooltip>
  );
};

interface ComfirnBidDialogProps {
  bidsState: BidReducerState;
  bidsDispatch: Dispatch<BidAction>;
  onFinish: () => void;
}
function ConfirmBidDialog({
  bidsState,
  bidsDispatch,
  onFinish,
}: ComfirnBidDialogProps) {
  const { selectedBid, isFinanceFeeAccepted, mortgage } = bidsState;
  const { openDialog, closeDialog, isShowing } = useDialog();

  const onFinanceFeeChange = () => {
    bidsDispatch({ type: "TOGGLE_FINANCE_FEE" });
  };
  const submitBidHandler = () => {
    if (selectedBid) {
      //if accepted call the service customerAcceptBid
      // let objBid = {
      //   bidId: selectedBid.map((bid) => bid.id)[0],
      //   mortgageId: mortgage.id,
      //   accept_financed_commission: true,
      // };
      // customerAcceptBid(mortgage.id, selectedBid, JSON.stringify(objBid))
      //   .then((res) => {
      //     bidsDispatch({ type: "BID_SUBMIT" });
      //     onFinish();
      //   })
      //   .catch((error) => {
      //     if (error?.response?.data?.meta?.response?.code === 409) {
      //       toast.warning(
      //         "Lo sentimos no puedes aceptar ofertas para esta solicitud, ya que las ofertas se han expirado."
      //       );
      //     }
      //     toast.error(
      //       "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
      //     );
      //     console.error(error);
      //   });

      const offerts = selectedBid.map((bid) => ({
        id: bid.id,
        accept_financed_commission: false,
      }));
      customerAcceptBids(mortgage.id, offerts).then((res) => {
        bidsDispatch({ type: "BID_SUBMIT" });
        onFinish();
      });
    }
  };
  return (
    <>
      <Button
        onClick={openDialog}
        variant="contained"
        disabled={!Boolean(selectedBid) || selectedBid?.length === 0}
        className="next-btn"
      >
        Siguiente
      </Button>
      {selectedBid && (
        <CustomDialog
          open={isShowing}
          onClose={closeDialog}
          id="confirm-bid-dialog"
        >
          {
            <Container>
              <DialogContentText
                color="primary"
                variant="h5"
                textAlign="center"
              >
                ¡Has seleccionado estas ofertas!
              </DialogContentText>

              <DialogContent>
                <DialogContentText
                  textAlign="left"
                  color="black"
                  fontWeight="500"
                >
                  Este es el resumen de las ofertas elegidas:
                </DialogContentText>
                <BidDetailsWithTabs
                  mortgage={mortgage}
                  bid={selectedBid}
                  isFinanceFeeAccepted={isFinanceFeeAccepted}
                  onFinanceFeeChange={onFinanceFeeChange}
                />
                <Typography
                  variant="caption"
                  display="block"
                  marginTop="1rem"
                  gutterBottom
                >
                  Por favor, asegúrate haber revisado los detalles de cada una
                  antes de continuar el proceso.
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  color="#6060DA"
                  gutterBottom
                >
                  ¡Atencion! una vez elegidas las ofertas, no será posible
                  volver atrás para elegir otras.
                </Typography>
              </DialogContent>
              <DialogContentText
                textAlign="start"
                variant="caption"
                paddingX={2}
              >
                {/* {getDescDialogText(selectedBid.bidder.bidder_type === "bank")} */}
              </DialogContentText>
              <DialogActions sx={{ justifyContent: "space-around" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "lightgray",
                    color: "black",
                    "&:hover": { backgroundColor: "gray" },
                  }}
                  onClick={closeDialog}
                >
                  Volver
                </Button>
                <Button variant="contained" onClick={submitBidHandler}>
                  Elegir oferta
                </Button>
              </DialogActions>
            </Container>
          }
        </CustomDialog>
      )}
    </>
  );
}

const getDescDialogText = (isBank: boolean) => {
  const asesor = isBank ? "banco" : "asesor";
  return isBank
    ? `*Es la comisión que cobrará el ${asesor} por la prestación de sus servicios, pero Colibid sigue siendo siempre gratis. Esta comisión no se abonará hasta la firma de la hipoteca ante notario.`
    : `*Es la comisión que cobrará el ${asesor} por la prestación de sus servicios, pero Colibid sigue siempre gratis.`;
};
