import React, {
  ReactComponentElement,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { NotResults } from "shared/components";
import { MortgageRes } from "shared/types/BaseApiResponse";
import { Bid } from "shared/types/Bid";
import { cancelAceptedBids } from "services/api";
import BidBubble from "shared/components/BidBubble";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
  Typography, Button
} from "@mui/material";

import styles from "./styles.module.scss";

interface PPOffersInterface {
  mortagage: MortgageRes;
  mortgageOfferts: Array<Bid>;
  fetching: boolean;
  changeTabOffers: (data: any, isFetching: boolean) => void;
}

const PPOffers = ({
  mortagage,
  mortgageOfferts
}: PPOffersInterface): ReactComponentElement<any> => {
  const [offerts, setOfferts] = useState<Array<Bid>>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setOfferts(mortgageOfferts);
    console.log(mortgageOfferts);
  }, [mortgageOfferts, mortagage]);

  const onCancel = (bid: string) => {
    cancelAceptedBids(mortagage.id, [{ id: bid }]).then((res) => {
      window.location.reload();
    });
  }

  return (
    <div>
      <Typography variant="caption" display="block" gutterBottom>
        <p>
          Aquí encontrarás el resumen de las ofertas elegidas. Podrás ver de
          cada una de ellas: información principal, paso del proceso en que se
          encuentra y grado de avance. Verás que puede pasar que algunas ofertas
          avancen más rápido en el proceso que otras. Esto es porque cada
          entidad tiene sus tiempos. Pero no te preocupes, al ingresar a cada
          una te contaremos qué necesitamos de ti y qué debes esperar de esa
          parte del proceso.
        </p>
      </Typography>
      <br />
      <div className="bid-bubbles-header w-fixed">
        <div className="bid-bubbles-title">
          <h1>Ofertas aceptadas</h1>
        </div>
        <div className="bid-bubbles-filters w-fixed">

        </div>
      </div>
      <br />
      <div className="bid-bubbles">
        {offerts.map((offert, i) => {
          return (
            <BidBubble key={`bd${i}`} bid={offert} mode="accepted" mortgage={mortagage} onSelect={() => { }} onTrash={(bid) => {
              onCancel(bid);
            }} />
          )
        })}
      </div>



      <div className={styles.FooterBox}>
        {offerts.length === 0 && (
          <NotResults
            title="Sin Resultados"
            caption="No se han encontrado resultados"
            url="/assets/img/sources/not_result_banks.svg"
            resize={true}
          />
        )}

        <Typography variant="caption" display="block" gutterBottom className={styles.CaptionNext}>
          El proximo paso es subir tus documentos, hazlo ahora haciendo click aquí o presiona el botón "IR A OFERTA"
        </Typography>
        <br />
        <Button onClick={() => {
          navigate(`/client/profile/documents?mid=${mortagage.id}`)
        }} variant="contained" startIcon={<CloudUploadIcon />}>
          Cargar documentación
        </Button>
      </div>
    </div>
  );
};

export default PPOffers;
