import { useState, useEffect } from "react";

import { MortgageRes } from "shared/types/BaseApiResponse";
import { Bid } from "shared/types/Bid";

import { FilterList } from "@mui/icons-material";

import BidBubble from "shared/components/BidBubble";
import { NotResults } from "shared/components";
import { BidReducerState } from "shared/components/BidSection/useBidReducer";

interface BidBubblesProps {
  mortgage: MortgageRes;
  onSelect: (bid: string) => void;
  bidsState: BidReducerState;
}

export default function BidBubbles({
  mortgage, onSelect, bidsState
}: BidBubblesProps) {
  const [bids, setBids] = useState<Bid[]>([]);
  const [activeFilter, setActiveFilter] = useState<string>("");

  useEffect(() => {
    const stepper = document.querySelector("#customized-steppers");
    
    if (stepper) {
      stepper.classList.add("stepper-bubbles");

      return () => {
        stepper.classList.remove("stepper-bubbles");
      };
    }
  }, []);

  useEffect(() => {
    setBids(bidsState.bids);
  }, [bidsState]);

  const filterBids = (mode: string) => {
    if (mode === activeFilter) {
      mode = "";
    }
  
    let filteredBids = [...bidsState.bids];
  
    switch (mode) {
      case "fixed":
        filteredBids = filteredBids.filter((b) => b.interest_data.mode === "fijo");
        break;
      case "variable":
        filteredBids = filteredBids.filter((b) => b.interest_data.mode === "variable");
        break;
      case "mixed":
        filteredBids = filteredBids.filter((b) => b.interest_data.mode === "mixto");
        break;
      case "bank":
        filteredBids = filteredBids.filter((b) => b.bidder.bidder_type === "bank");
        break;
      case "broker":
        filteredBids = filteredBids.filter((b) => b.bidder.bidder_type === "broker");
        break;
      case "lowest_fee":
        filteredBids.sort((a, b) => a.calculated.monthly - b.calculated.monthly);
        break;
      case "highest_fee":
        filteredBids.sort((a, b) => b.calculated.monthly - a.calculated.monthly);
        break;
      default:
        break;
    }
  
    setBids(filteredBids);
    setActiveFilter(mode);
  };

  return (
    <>
      <div className="bid-bubbles-header">
        <div className="bid-bubbles-title">
          <h1>Ofertas <span className="text-since">{bids.length}</span><span className="text-since">/{bidsState.bids.length}</span> total</h1>
        </div>
        <div className="bid-bubbles-filters">
          <FilterList color="primary" />

          <button type="button" className={`bid-bubbles-btn-filter ${activeFilter === "fixed" ? "active" : ""}`} onClick={() => {
            filterBids("fixed");
          }}>Fijo</button>
          <button type="button" className={`bid-bubbles-btn-filter ${activeFilter === "variable" ? "active" : ""}`} onClick={() => {
            filterBids("variable");
          }}>Variable</button>
          <button type="button" className={`bid-bubbles-btn-filter ${activeFilter === "mixed" ? "active" : ""}`} onClick={() => {
            filterBids("mixed");
          }}>Mixto</button>
          <button type="button" className={`bid-bubbles-btn-filter ${activeFilter === "bank" ? "active" : ""}`} onClick={() => {
            filterBids("bank");
          }}>Banco</button>
          <button type="button" className={`bid-bubbles-btn-filter ${activeFilter === "broker" ? "active" : ""}`} onClick={() => {
            filterBids("broker");
          }}>Broker</button>
          <button type="button" className={`bid-bubbles-btn-filter ${activeFilter === "lowest_fee" ? "active" : ""}`} onClick={() => {
            filterBids("lowest_fee");
          }}>Cuota mínima</button>
          {/* <button type="button" className={`bid-bubbles-btn-filter ${activeFilter === "highest_fee" ? "active" : ""}`} onClick={() => {
            filterBids("highest_fee");
          }}>Cuota más alta</button> */}
        </div>
      </div>

      <div className="bid-bubbles">
        {bids.map((bid, i) => {
          return (
            <BidBubble key={`bd${i}`} bid={bid} mode="await" mortgage={bidsState.mortgage} bidsState={bidsState} onSelect={onSelect} onTrash={() => { }} />
          )
        })}
      </div>

      {/* {bids.length > 0 && <div className="bid-bubbles-counter">
        Mostrando {bids.length}/{bidsState.bids.length} ofertas recibidas
      </div>} */}

      <div className="bid-bubbles-footer">
        {bids.length === 0 && (
          <NotResults
            title="Sin Resultados"
            caption="No se han encontrado resultados"
            url="/assets/img/sources/not_result_banks.svg"
            resize={true}
          />
        )}
      </div>
    </>
  );
}
