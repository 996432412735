import {
  FunctionComponent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { TopBar, LeftBarMenu, Loader, BottomNav } from "../../components";

import subject, { HAS_FORM, TOGGLE_LOADER } from "../../../utils/subjects";
import CustomAlert from "shared/components/CustomAlert";
import { DIALOGS, MORTGAGE_FORM_STATE } from "shared/constants/localStorage";
import { useLocation, useNavigate } from "react-router-dom";
import { deepSameKeys } from "utils";
import dataStorageService from "services/api/dataStorage.service";
import { getInitialFormData } from "shared/components/MortgageForm/utils";
import { toast } from "react-toastify";
import { INITIAL_STEP } from "shared/constants/general";
import { useProfile, useSetVhRef } from "shared/hooks";
import Swal from "sweetalert2";
import { useLocalStorage } from "usehooks-ts";
import { ClientProfile } from "shared/types/Profile";
import { MortgageFormState } from "shared/components/MortgageForm/hooks/useMortgageFormReducer";
import { Description, Home, Person, BusinessCenter, Dashboard } from "@mui/icons-material";
export interface IMainClientLayoutProps {
  children: JSX.Element;
}

const MainClientLayout: FunctionComponent<IMainClientLayoutProps> = ({
  children,
}): ReactElement<any> => {
  useChangePassDialog();
  const { elementRef: mainSectionRef } = useSetVhRef();
  const [loading, setLoading] = useState(false);

  const navOptions = [
    {
      label: "Documentos",
      path: "/client/profile/documents",
      icon: <Description fontSize="large"/>
      // iconUrl:
      //   "https://colibid.fra1.digitaloceanspaces.com/colibid%2Fpublic%2Fassets%2Fimg%2Fdocument-unscreen.gif",
    },
    // {
    //   can_create_mortgage: true,
    //   label: "Nueva solicitud",
    //   path: "/client/mortgage/create?step=0",
    //   iconUrl:
    //     "https://colibid.com/wp-content/uploads/2023/03/49-plus-circle-outline-1.gif",
    // },
    {
      can_create_mortgage: false,
      label: "Mi hipoteca",
      path: (id: string) => `/client/mortgage/${id}`,
      icon: <Home fontSize="large"/>,
      // iconUrl:
      //   "https://colibid.fra1.digitaloceanspaces.com/colibid%2Fpublic%2Fassets%2Fimg%2Fhome-unscreen.gif",
    },
    {
      label: "Panel principal",
      path: "/client/home",
      icon: <Dashboard fontSize="large"/>,
      // iconUrl:
      //   "https://colibid.fra1.digitaloceanspaces.com/colibid%2Fpublic%2Fassets%2Fimg%2Fpresentation-rm-bg.gif",
    },
    {
      label: "Perfil",
      path: "/client/profile",
      icon: <Person fontSize="large"/>,
      // iconUrl:
      //   "https://colibid.fra1.digitaloceanspaces.com/colibid%2Fpublic%2Fassets%2Fimg%2Fuser-unscreen.gif",
    },
    {
      label: "Solicitudes",
      path: "/client/mortgages",
      icon: <BusinessCenter fontSize="large"/>,
      // iconUrl:
      //   "https://colibid.com/wp-content/uploads/2023/03/187-suitcase-outline.gif",
      // sistema-solar
    },
  ];

  useEffect(() => {
    const toggleLoader = subject
      .listen(TOGGLE_LOADER)
      .subscribe(async ({ value }) => {
        setLoading(value);
      });

    return () => {
      if (toggleLoader) {
        toggleLoader.unsubscribe();
      }
    };
  }, []);

  return (
    <>
      {loading && <Loader />}
      <TopBar allowLeftBarMenu={true} showAuthControls={true} />
      <LeftBarMenu role="client" />
      <MortgageFormCachedAlert />
      <section id="content" className="main-layout client" ref={mainSectionRef}>
        {children}
      </section>
      <BottomNav navBtnOptions={navOptions} />
    </>
  );
};

export default MainClientLayout;

function MortgageFormCachedAlert() {
  const {profile} = useProfile<ClientProfile>()
  const navigate = useNavigate();
  const location = useLocation();
  const [hasForm, setHasForm] = useState(false);
  const stepRef = useRef(0);

  const isCreateMortgageFormRoute =
    location.pathname === "/signup" ||
    location.pathname === "/client/mortgage/create" ||
    location.pathname === "/client/mortgages/form/success/completed";

  const handleMortgageFormAccept = () => {
    // todo revisar esto component did mount
    navigate(`/client/mortgage/create?${INITIAL_STEP}=${stepRef.current}`);
  };
  const handleMortgageFormCancel = () => {
    dataStorageService
      .delData({ key: MORTGAGE_FORM_STATE })
      .then((res) => {
        setHasForm(false);
      })
      .catch((err) => {
        toast.error(
          "Hubo un error eliminando el formulario guardado, inténtelo de nuevo en unos momentos 😅"
        );
      });
  };

  useEffect(() => {
    if (!isCreateMortgageFormRoute && profile?.can_create_mortgage) {
      dataStorageService
        .getData<MortgageFormState>(MORTGAGE_FORM_STATE)
        .then((res) => {
          const initialData = getInitialFormData({
            name: "",
            lastname: "",
            email: "",
          });
          const dataCached = res.data.results.payload;
          const isSameForms = deepSameKeys(
            initialData,
            dataCached.mortgageFormData
          );

          if (isSameForms) {
            setHasForm(isSameForms);
            stepRef.current = dataCached.mortgageFormStep;
            return;
          }
          dataStorageService.delData({ key: MORTGAGE_FORM_STATE });
        })
        .catch((err) => {
          setHasForm(false);
        });
    }
  }, [profile]);

  if (!isCreateMortgageFormRoute && hasForm) {
    return (
      <CustomAlert
        title="¿Desea retomar el formulario donde lo dejó la última vez?"
        acceptCb={handleMortgageFormAccept}
        cancelCb={handleMortgageFormCancel}
      />
    );
  }

  return null;
}

function useChangePassDialog() {
  const [hasSeen, setHasSeen] = useLocalStorage(DIALOGS.CHANGE_PASSWORD, false);
  const [tmpAuth] = useLocalStorage("tmpAuth", false);
  const location = useLocation();
  const isCreateMortgageFormRoute =
    location.pathname === "/signup" ||
    location.pathname === "/client/mortgage/create" ||
    location.pathname === "/client/mortgages/form/success/completed";
  const navigate = useNavigate();
  const { profile } = useProfile<ClientProfile>();



  useEffect(() => {
    const shouldShowDialog =
      location.pathname === "/client/profile/changepassword" ||
      isCreateMortgageFormRoute;
    if (
      !shouldShowDialog &&
      profile?.additional?.has_default_password &&
      !hasSeen && !tmpAuth
    ) {
      Swal.fire({
        title: 'Por favor, establezca su contraseña en "Mi perfil".',
        text: "Si no lo haces ahora, deberás “Recuperar contraseña” la próxima vez que ingreses a la plataforma.",
        icon: "warning",
        padding: "1rem",
        showDenyButton: true,
        denyButtonColor: "#CCCCCC",
        denyButtonText: "<span style='color: black'>MÁS TARDE</span>",
        confirmButtonText: "IR A MI PERFIL",
      }).then((res) => {
        if (res.isConfirmed) {
          navigate("/client/profile/changepassword");
        } else {
          setHasSeen(true);
        }
      });
    }
  }, [profile, tmpAuth]);
}
