import { Cell, ColumnInstance, Row } from "react-table";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { FormControlLabel } from "@mui/material";
import styles from "./styles.module.scss";
import Swal from "sweetalert2";
import { useState } from "react";
import {
  BaseApiSuccessRes,
  MortgagePartnerAgencies,
} from "shared/types/BaseApiResponse";
import {getApplicationType} from "../../../utils/helpers";

export interface DataTable {
  id: string;
  fullname: string;
  email: string;
  status: string;
  mortgageType: string;
  mortgageId: string;
  awaitingSignature: number;
}

export const modifyData = (
  obj: BaseApiSuccessRes<MortgagePartnerAgencies[]>
) => {
  let res: any = {
    data: [],
    mortageToApply: [],
  };
  let data: Array<DataTable> = [];
  if (obj.results.length > 0) {
    data = obj.results.map((item) => {
      let objData: DataTable = {
        id: "",
        fullname: "",
        email: "",
        status: "",
        mortgageType: "",
        mortgageId: "", 
        awaitingSignature: 0, 
      };

      objData.id = item.id;
      objData.fullname = `${item.first_name} ${item.last_name}`;
      objData.email = item.email;

      if (item.mortgage) {
        objData.mortgageId = item.mortgage.id;
        objData.status = item.mortgage.status.toString();
        objData.mortgageType = getApplicationType(item.mortgage.mortgage_data.mortgage.mode);
        objData.awaitingSignature = item.awaiting_signature;
      }

      return objData;
    });
  }

  res.data = data;
  return res;
};
